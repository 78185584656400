import React from "react";
import "./StickyHeader.scss";

import { useHistory } from "react-router-dom";
import { HiArrowLeft } from "react-icons/hi";

const StickyHeader = (props) => {
  let history = useHistory();
  return (
    <div className="StickyHeader">
      <div className="title">
        {props.back && <HiArrowLeft onClick={() => history.goBack()} />}
        <p>{props.title}</p>
      </div>
    </div>
  );
};

export default StickyHeader;
