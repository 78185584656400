import React from "react";
import { Link } from "react-router-dom";
import "./FooterTW.scss";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import { locale } from "../../../translate/spain";

const FooterTW = () => {
  return (
    <footer className="FooterTW">
      <div className="footer-links">
        <Link to="/">{locale.links.contact}</Link>
        <Link to="/about-us">{locale.links.about}</Link>
        <Link to="/">{locale.links.privacyPolicy}</Link>
        <Link to="/">{locale.links.termsAndConditions}</Link>
      </div>
      <div className="footer-social">
        <a href={locale.footer.facebookLink} target="_blanko">
          <FaFacebook />
        </a>

        <a href={locale.footer.instagramLink} target="_blanko">
          <FaInstagram />
        </a>

        <a href={locale.footer.twitterLink}>
          <FaTwitter />
        </a>
      </div>
      <div className="footer-bottom">
        <p>&copy; {locale.footer.copyRight}</p>
      </div>
    </footer>
  );
};

export default FooterTW;
