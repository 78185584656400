import React from "react";
import "./Personell.scss";

import Stars from "react-stars-display";

// import { FaRegUser } from "react-icons/fa";
import { MdKeyboardArrowRight } from "react-icons/md";
import { locale } from "../../../translate/spain";

const Personell = (props) => {
  const clicked = (person) => {
    if (props.select) {
      props.select(person);
    }
  };

  const personell = props.persons.filter((elem) =>
    elem.services.some((el) => el.id === props.service_id)
  );

  const persons =
    personell.length > 0 ? (
      personell.map((person, index) => (
        <div
          key={index}
          className={"person" + (props.select ? " selectable" : "")}
          onClick={() => clicked(person)}
        >
          <div className="about">
            <div className="img">
              <img src={person.avatar_fake} alt="Person Avatar" />
            </div>
            <div className="description">
              <h5>{person.first_name}</h5>
              <p>{person.last_name}</p>
              <div className="rating">
                <Stars
                  stars={person.average_rating ? person.average_rating : 0.0}
                  size={14}
                />
                <span>
                  ({person.average_rating ? person.average_rating : "0.0"})
                  {/* <span className="resensioner"> reviews</span>) */}
                </span>
              </div>
            </div>
          </div>
          {props.select ? (
            <MdKeyboardArrowRight className="select" />
          ) : (
            <button className="choose">{locale.book.choose}</button>
          )}
        </div>
      ))
    ) : (
      <div>
        <p>{locale.book.noWorkers}</p>
      </div>
    );

  return <div className="Personell">{persons}</div>;
};

export default Personell;
