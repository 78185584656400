import React from "react";
import "./StoreSwiper.scss";
import SwiperCore, {
  EffectCoverflow,
  Pagination,
  Autoplay,
  Navigation,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import storeShopIcon from "../../../assets/LandingPage/storeshop-icon.png";
// import popularPlace from "../../../assets/LandingPage/popularPlace.jpg";
// import popularPlace2 from "../../../assets/LandingPage/popularPlace2.jpg";

SwiperCore.use([EffectCoverflow, Pagination, Autoplay, Navigation]);

const StoreSwiper = ({ logo }) => {
  return (
    <Swiper
      navigation
      // pagination={{ clickable: true }}
      // centeredSlides
      slidesPerView={1}
      // spaceBetween={5}
      loop="true"
      className="StoreSwiper">
      <SwiperSlide>
        <div className="SearchPicture">
          <img src={logo ? logo : storeShopIcon} alt="Hair Area" />
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="SearchPicture">
          <img src={logo ? logo : storeShopIcon} alt="Hair Area" />
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default StoreSwiper;
