import React, { useEffect } from "react";
import "./App.scss";

import { connect, useSelector } from "react-redux";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import { SnackbarProvider } from "notistack";
import { makeStyles } from "@material-ui/core/styles";

import ScrollToTop from "./hoc/ScrollToTop/ScrollToTop";
import Notification from "./containers/Notification/Notification";
import LoadingScreen from "./containers/views/LoadingScreen/LoadingScreen";

import LandingPage from "./containers/views/LandingPage/LandingPage";
import Login from "./containers/views/Login/Login";
import Signup from "./containers/views/Signup/Signup";
import CheckEmail from "./containers/views/CheckEmail/CheckEmail";
import Search from "./containers/views/Search/Search";
import AboutUs from "./containers/views/AboutUs/AboutUs";
import CancelBooking from "./containers/views/CancelBooking/CancelBooking";
import Place from "./containers/views/Place/Place";
import Profile from "./containers/views/Profile/Profile";
import Booking from "./containers/views/Booking/Booking";
import Favourite from "./containers/views/Favourite/Favourite";
import BookingPlace from "./containers/views/BookingPlace/BookingPlace";
import Book from "./containers/views/Book/Book";
import NotFoundPage from "./containers/views/NotFoundPage/NotFoundPage";
import ForgotPassword from "./containers/views/ForgotPassword/ForgotPassword";
import ResetPassword from "./containers/views/ResetPassword/ResetPassword";
import CanceledAppointment from "./containers/views/CanceledAppointment/CanceledAppointment";

import { getProfile } from "./store/actions/account";

const useStyles = makeStyles((theme) => ({
  variantSuccess: {
    backgroundColor: "#1A73E8 !important",
    borderRadius: "8px",
  },
  variantError: { backgroundColor: "#F64E60 !important", borderRadius: "8px" },
}));

function App(props) {
  const authorized = useSelector((state) => state.account.authorized);
  const classes = useStyles();

  useEffect(() => {
    props.getProfile();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let routes = [
    <Route path='/login/:type' component={Login} />,
    <Route path='/signup' component={Signup} />,
    <Route path='/bookings/:type/:placeId/:id' component={BookingPlace} />,
    <Route path='/profile' component={Profile} />,
    <Route path='/bookings' component={Booking} />,
    <Route path='/favourite' component={Favourite} />,
    <Route path='/canceled-appointment/:id' component={CanceledAppointment} />,
  ];

  if (authorized) {
    routes.splice(0, 2);
  } else if (authorized === false) {
    routes.splice(2, 5);
  }

  return (
    <SnackbarProvider
      classes={classes}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      maxSnack={2}>
      <div className='App'>
        <BrowserRouter>
          <ScrollToTop>
            <LoadingScreen />
            <Notification />
            <Switch>
              <Route path='/' exact component={LandingPage} />
              <Route path='/check-email' component={CheckEmail} />
              <Route path='/search' component={Search} />
              <Route path='/about-us' component={AboutUs} />
              <Route path='/cancel-booking' component={CancelBooking} />
              <Route path='/place/:id' component={Place} />
              <Route path='/book/:id' component={Book} />
              <Route path='/forgot-password' component={ForgotPassword} />
              <Route path='/reset-password/:id' component={ResetPassword} />

              {routes}
              <Route path='*' component={NotFoundPage} />
            </Switch>
          </ScrollToTop>
        </BrowserRouter>
      </div>
    </SnackbarProvider>
  );
}

export default connect(null, { getProfile })(App);
