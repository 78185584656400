import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { locale } from "../../../translate/spain";
import Bookybook from "../../../assets/Signup/Logo.png";
import { resetPassword } from "../../../store/actions/account";

import "./ResetPassword.scss";

const ResetPassword = ({ match, location, history }) => {
  const dispatch = useDispatch();

  const [passwords, setPasswords] = useState({
    password: "",
    password_confirmation: "",
  });

  const submitForm = (e) => {
    e.preventDefault();
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get("email");

    dispatch(resetPassword(passwords, match.params.id, email, history));
  };

  const handlePasswords = (event) => {
    let input = passwords;
    input[event.target.name] = event.target.value;

    setPasswords((prevState) => {
      return { ...prevState, ...input };
    });
  };

  return (
    <div className='ResetPassword'>
      <form onSubmit={submitForm}>
        <div className='form_header'>
          <img src={Bookybook} />
          <h1>{locale.auth.changePasswordForm.title}</h1>
        </div>
        <div className='form_body'>
          <label for='helper-text' className='helper-text'>
            {locale.auth.changePasswordForm.label1}
          </label>
          <input
            type='password'
            id='helper-text'
            name='password'
            value={passwords.password}
            onChange={handlePasswords}
            aria-describedby='helper-text-explanation'
          />

          <label for='helper-text' className='helper-text'>
            {locale.auth.changePasswordForm.label2}
          </label>

          <input
            type='password'
            id='helper-text'
            name='password_confirmation'
            value={passwords.password_confirmation}
            onChange={handlePasswords}
            aria-describedby='helper-text-explanation'
          />

          <button type='submit'>{locale.auth.changePasswordForm.button}</button>
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
