import React, { useState } from "react";
import "./ProfileOptions.scss";
import { Link } from "react-router-dom";
import { connect, useSelector } from "react-redux";

import { MdAccountCircle } from "react-icons/md";
import { AiOutlineSetting, AiOutlineCalendar } from "react-icons/ai";
import { BiLogOut } from "react-icons/bi";
import { FiHeart } from "react-icons/fi";

import { logout } from "../../../../store/actions/account";
import { locale } from "../../../../translate/spain";

const ProfileOptions = (props) => {
  const authorized = useSelector((state) => state.account.authorized);

  let links = (
    <>
      <Link to="/login/auth">
        <p>{locale.navbar.login}</p>
      </Link>
      <Link to="/signup">
        <p>{locale.navbar.signup}</p>
      </Link>
      <Link to="/cancel-booking">
        <p>{locale.navbar.cancelBooking}</p>
      </Link>
    </>
  );

  if (authorized) {
    links = (
      <>
        <Link to="/bookings">
          <AiOutlineCalendar />
          <p>{locale.navbar.myReservations}</p>
        </Link>
        <Link to="/favourite">
          <FiHeart />
          <p>{locale.navbar.myFavorites}</p>
        </Link>
        <Link to="/profile">
          <AiOutlineSetting />
          <p>{locale.navbar.profile}</p>
        </Link>
        <button className="logout" onClick={() => props.logout()}>
          <BiLogOut />
          <p>{locale.navbar.logout}</p>
        </button>
        <hr />
        <Link to="/cancel-booking">
          <p>{locale.navbar.cancelBooking}</p>
        </Link>
        {/* <Link to="/cancel-booking">
          <p>Atención al cliente</p>
        </Link> */}
      </>
    );
  }

  return (
    <div className="ProfileOptions">
      <div className="display" onClick={(e) => props.toggleNavigation(e)}>
        <div className="hamburger">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        <MdAccountCircle />
      </div>

      {props.isOpen ? (
        <div className="popup">
          {links}
          <div className="title">
            <p style={{ textTransform: "uppercase" }}>
              {locale.navbar.forProfesionals}
            </p>
          </div>
          <a href={process.env.REACT_APP_B2B_LINK}>
            <p>{locale.navbar.forProfesionals}</p>
          </a>
          <a href={process.env.REACT_APP_LINK_LOGIN}>
            <p>{locale.navbar.login}</p>
          </a>
          <a href={process.env.REACT_APP_B2B_LINK_PRICE}>
            <p>{locale.navbar.price}</p>
          </a>
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.account.profile,
  };
};

export default connect(mapStateToProps, { logout })(ProfileOptions);
