import { locale } from "../translate/spain";

export const calculateGrade = (grade) => {
  if (grade >= 1 && grade <= 2) {
    return locale.grades["1-2"];
  } else if (grade >= 2.1 && grade <= 3.5) {
    return locale.grades["2-3.5"];
  } else if (grade >= 3.6 && grade <= 4.4) {
    return locale.grades["3.5-4.5"];
  } else if (grade >= 4.5) {
    return locale.grades["4.5-5"];
  } else {
    return locale.grades.unrated;
  }
};
