import React from "react";
import "./CancelBooking.scss";

import PageLayout from "../../../hoc/PageLayout/PageLayout";
import StickyHeader from "../../../components/Utils/StickyHeader/StickyHeader";
import CancelCode from "../../../components/CancelBooking/CancelCode/CancelCode";
import { locale } from "../../../translate/spain";

const CancelBooking = (props) => {
  return (
    <PageLayout extended>
      <div className="CancelBooking">
        <StickyHeader title={locale.cancelBooking.heading} />
        <CancelCode />
      </div>
    </PageLayout>
  );
};

export default CancelBooking;
