import React from "react";
import "./SinglePopularPlace.scss";
// import popularPlace from "../../../assets/LandingPage/popularPlace.jpg";
import storeShopIcon from "../../../assets/LandingPage/storeshop-icon.png";
import { FaStar } from "react-icons/fa";
import { Link } from "react-router-dom";
import { locale } from "../../../translate/spain";
import { calculateGrade } from "../../../helpers/calculateGrade";

const SinglePopularPlace = ({ place }) => {
  return (
    <Link to={`/place/${place.id}`} className='SinglePopularPlace'>
      <img src={place.logo ? place.logo : storeShopIcon} alt='Popular place' />
      <h1>{place.name}</h1>
      <p>{place.address}</p>
      <div className='flex'>
        <FaStar />
        <span>
          {place.average_rating
            ? `${place.average_rating} ${calculateGrade(place.average_rating)}`
            : `${place.average_rating} ${calculateGrade(0)}`}
        </span>
        <p className='big-rate'>
          {place.reviews
            ? place.reviews.length + ` ${locale.grades.ratings}`
            : "10"}
        </p>
        <p className='small-rate'>({place.reviews.length})</p>
      </div>
      <div className='featured'>{locale.featured}</div>
    </Link>
  );
};

export default SinglePopularPlace;
