import React, { useState } from "react";

import Navigation from "../../components/Layout/Navigation/Navigation";
import Footer from "../../components/Layout/FooterTW/FooterTW";

const PageLayout = (props) => {
  const [openNavigation, setOpenNavigation] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);

  const toggleNavigation = (e) => {
    if (!openNavigation) {
      if (
        e.target.className === "display" ||
        e.target.className === "hamburger" ||
        e.target.className === "line"
      ) {
        document.body.style.overflow = "hidden";
        setOpenNavigation(true);
      }
    } else {
      document.body.style.overflow = "unset";
      setOpenNavigation(false);
    }
  };

  return (
    <div className="PageLayout" onClick={(e) => toggleNavigation(e)}>
      <Navigation
        sticky={props.sticky}
        navigation={openNavigation}
        toggleNavigation={toggleNavigation}
        openProfile={openProfile}
        toggleOpenProfile={() => setOpenProfile((p) => !p)}
        main={props.main}
        searchResults={props.searchResults}
        landingPage={props.landingPage}
        searchForm={props.searchForm}
        term={props.term ? props.term : ""}
        location={props.location ? props.location : ""}
        handleFirstInputClick={props.handleFirstInputClick}
        handleSecondInputClick={props.handleSecondInputClick}
        firstInputFocus={props.firstInputFocus}
        secondInputFocus={props.secondInputFocus}
        place={props.place}
      />
      {props.children}
      {props.footer ? <Footer extended={props.extended} /> : null}
    </div>
  );
};

export default PageLayout;
