import React from "react";
import "./PlaceName.scss";

import Stars from "react-stars-display";
import { calculateGrade } from "../../../helpers/calculateGrade";

const placeName = ({ place }) => {
  return (
    <div className="PlaceName">
      <div className="container">
        <div className="flex">
          <h1>{place && place.name}</h1>
          <div className="rating">
            <Stars
              stars={place && place.average_rating}
              size={24}
              fill="#FFC830"
            />
            <p>
              {place &&
                `${place.average_rating} ${calculateGrade(
                  place.average_rating
                )}`}
            </p>
            <span>({place && place.reviews && place.reviews.length})</span>
          </div>
          <h5>{place && place.address}</h5>
        </div>
        {/* <span>{place && place.address}</span> */}
      </div>
    </div>
  );
};

export default placeName;
