import React, { useState, useEffect } from "react";
import "./Search.scss";
import { useSelector, useDispatch } from "react-redux";
// import SearchBar from "../../../components/Search/SearchBar/SearchBar";
import SearchResult from "../../../components/Search/SearchResult/SearchResult";

import PageLayout from "../../../hoc/PageLayout/PageLayout";

import { getSearchedPlaces } from "../../../store/actions/search";
import { fetchCategories } from "../../../store/actions/categories";

const Search = (props) => {
  const dispatch = useDispatch();
  const [term, setTerm] = useState("");
  const [location, setLocation] = useState("");
  const [category, setCategory] = useState("");
  // const [catName, setCatName] = useState("");
  const { searchedPlaces } = useSelector((state) => state.search);
  const categories = useSelector((state) => state.categories);

  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    setLocation(query.get("location"));
    setCategory(query.get("category"));
    setTerm(query.get("term"));
    // setCategory(query.get("latitude"));
    // setCategory(query.get("longitude"));
    // setTerm(query.get("filter"));

    dispatch(
      getSearchedPlaces(
        query.get("category"),
        query.get("latitude") !== null ? query.get("latitude") : "",
        query.get("longitude") !== null ? query.get("longitude") : "",
        query.get("term"),
        query.get("location"),
        query.get("filter") &&
          query.get("filter") !== "Nearest" &&
          query.get("filter").length > 0
          ? "Best rated"
          : ""
      )
    );

    // if (term === "" && category !== "") {
    //   const cat = categories.find((elem) => elem.id == category);
    //   setCatName(cat.name);
    //   console.log(cat.name);
    //

    // eslint-disable-next-line
  }, [props.location.search]);

  const searchbyFilter = (filter) => {
    const query = new URLSearchParams(props.location.search);

    const categoryValue = query.get("category") ? query.get("category") : "";
    const latitudeValue = query.get("latitude") ? query.get("latitude") : "";
    const longitudeValue = query.get("longitude") ? query.get("longitude") : "";
    const termValue = query.get("term") ? query.get("term") : "";
    const locationValue = query.get("location") ? query.get("location") : "";

    if (filter) {
      const finallUrl = `?category=${categoryValue}&latitude=${latitudeValue}&longitude=${longitudeValue}&term=${termValue}&filter=${filter}&location=${locationValue}`;

      props.history.push({
        pathname: "/search",
        search: finallUrl,
      });
    }
  };

  useEffect(() => {
    categories.length === 0 && dispatch(fetchCategories());
    if (term === "" && category !== "") {
      const cat = categories.find((elem) => elem.id === parseInt(category));
      if (cat) {
        setTerm(cat.name);
      }
    }
    // eslint-disable-next-line
  }, [term, category, location, categories, props.location.search]);

  // const setNameHandler = (e) => {
  //   setName(e.target.value);
  // };

  // const setLocationHandler = (e) => {
  //   setLocation(e.target.value);
  // };

  // const deleteNameHandler = (e) => {
  //   e.preventDefault();
  //   setName("");
  // };

  // const deleteLocationHandler = (e) => {
  //   e.preventDefault();
  //   setLocation("");
  // };

  const [firstInputClick, setFirstInputClick] = useState(false);
  const [secondInputClick, setSecondInputClick] = useState(false);

  const handleFirstInputClick = (val) => {
    setFirstInputClick(val);
  };

  const handleSecondInputClick = (val) => {
    setSecondInputClick(val);
  };

  return (
    <PageLayout
      searchResults
      searchForm
      term={term}
      location={location}
      // onClick={() => {
      //   if (firstInputClick || secondInputClick) {
      //     setFirstInputClick(false);
      //     setSecondInputClick(false);
      //   }
      // }}
      handleFirstInputClick={handleFirstInputClick}
      handleSecondInputClick={handleSecondInputClick}
      firstInputFocus={firstInputClick}
      secondInputFocus={secondInputClick}>
      {/* <div className="Search">
        <div className="container">
          <div className="search-bar">
            <SearchBar
              name={name}
              location={location}
              setName={setNameHandler}
              setLocation={setLocationHandler}
              deleteName={deleteNameHandler}
              deleteLocation={deleteLocationHandler}
            />
          </div>
        </div>
      </div> */}
      <SearchResult
        stores={searchedPlaces}
        term={term}
        // catName={catName}
        location={location}
        onClick={() => {
          if (firstInputClick || secondInputClick) {
            setFirstInputClick(false);
            setSecondInputClick(false);
          }
        }}
        searchbyFilter={searchbyFilter}
      />
    </PageLayout>
  );
};

export default Search;
