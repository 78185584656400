import React from "react";
import "./Toggle.scss";

const Toggle = (props) => {
  return (
    <div
      className={"Toggle" + (props.active ? " active" : "")}
      onClick={props.toggle}
    >
      <div className="input"></div>
    </div>
  );
};

export default Toggle;
