import * as actionTypes from "../actions/actionTypes";

const initialState = {
  freeSlots: null,
  active: [],
  nonActive: [],
  cancelled: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FREE_SLOTS:
      return {
        ...state,
        freeSlots: action.freeSlots,
      };
    case actionTypes.SET_ACTIVE_APPOINTMENTS:
      return {
        ...state,
        active: action.active,
      }
    case actionTypes.SET_NONACTIVE_APPOINTMENTS:
      return {
        ...state,
        nonActive: action.nonActive
      }
    case actionTypes.SET_CANCELLED_APPOINTMENTS:
      return {
        ...state,
        cancelled: action.cancelled
      }
    default:
      return state;
  }
};

export default reducer;
