import React, { useEffect, useState } from "react";
import "./Profile.scss";

import { CSSTransition } from "react-transition-group";
import { connect } from "react-redux";

import { updateProfile } from "../../../store/actions/account";

import PageLayout from "../../../hoc/PageLayout/PageLayout";
import StickyHeader from "../../../components/Utils/StickyHeader/StickyHeader";
import ProfilePicture from "../../../components/Signup/ProfilePicture/ProfilePicture";
import Input from "../../../components/Signup/SignupInput/SignupInput";

import EditEmail from "../../Profile/EditEmail/EditEmail";
import DeleteAccount from "../../Profile/DeleteAccount/DeleteAccount";
import ChangePassword from "../../Profile/ChangePassword/ChangePassword";
import { locale } from "../../../translate/spain";

const Profile = (props) => {
  const [profile, setProfile] = useState({});

  const [editEmail, setEditEmail] = useState(false);
  const [deleteAccount, setDeleteAccount] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  
  useEffect(() => {
    setProfile(props.profile);
  }, [props.profile]);

  const changeFirstNameHandler = (property) => {
    setProfile((p) => ({ ...p, first_name: property }));
  };

  const changeLastNameHandler = (property) => {
    setProfile((p) => ({ ...p, last_name: property }));
  };

  const changeEmailHandler = (property) => {
    setProfile((p) => ({ ...p, email: property }));
  };

  const changePhoneHandler = (property) => {
    setProfile((p) => ({ ...p, phone: property }));
  };

  const changeAddressHandler = (property) => {
    setProfile((p) => ({ ...p, address: property }));
  };

  const updateProfile = () => {
    props.updateProfile(profile.first_name, profile.last_name, profile.phone, profile.address);
  };

  return (
    <PageLayout>
      <div className="Profile">
        <StickyHeader title={locale.profile.heading} />
        <div className="container">
          <div className="main">
            <ProfilePicture settings avatar={profile && profile.avatar} />
            <hr />

            <div className="grid">
              <Input
                label={locale.auth.firstNameLabel}
                value={profile && profile.first_name}
                onChange={changeFirstNameHandler}
              />
              <Input
                label={locale.auth.lastNameLabel}
                value={profile && profile.last_name}
                onChange={changeLastNameHandler}
              />
              <Input
                label={locale.auth.emailLabel}
                value={profile && profile.email}
                disable
              />
              <Input
                label={locale.auth.mobileNumberLabel}
                value={profile && profile.phone}
                onChange={changePhoneHandler}
              />
              <button onClick={() => setEditEmail(true)}>
                {locale.profile.editEmail}
              </button>

              <Input
                label={locale.auth.addressLabel}
                value={profile && profile.address}
                onChange={changeAddressHandler}
              />
            </div>

            <button className="update" onClick={updateProfile}>
              {locale.profile.save}
            </button>

            <div className="changes">
              <div className="change">
                <label>{locale.auth.passwordLabel}</label>
                <div className="type">
                  <p>{locale.profile.changePasswordDesc}</p>
                  <button onClick={() => setChangePassword(true)}>
                    {locale.profile.changePassBtn}
                  </button>
                </div>
              </div>

              <div className="change">
                <label>{locale.profile.deleteAccountTitle}</label>
                <div className="type">
                  <p>{locale.profile.deleteAccountDesc}</p>
                  <button
                    className="delete"
                    onClick={() => setDeleteAccount(true)}>
                    {locale.profile.deleteAccountBtn}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CSSTransition
        in={editEmail}
        timeout={300}
        classNames="my-popup"
        mountOnEnter
        unmountOnExit>
        <EditEmail
          close={() => setEditEmail(false)}
          setEmail={changeEmailHandler}
          translate={locale.profile.editEmailAddress}
        />
      </CSSTransition>

      <CSSTransition
        in={deleteAccount}
        timeout={300}
        classNames="my-popup"
        mountOnEnter
        unmountOnExit>
        <DeleteAccount close={() => setDeleteAccount(false)} translate={locale.profile.deleteAccount}/>
      </CSSTransition>

      <CSSTransition
        in={changePassword}
        timeout={300}
        classNames="my-popup"
        mountOnEnter
        unmountOnExit>
        <ChangePassword
          close={() => setChangePassword(false)}
          email={profile && profile.email}
          translate={locale.profile.resetPassword}
        />
      </CSSTransition>
    </PageLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.account.profile,
  };
};

export default connect(mapStateToProps, { updateProfile })(Profile);
