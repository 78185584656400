import React, { useRef, useState, useEffect } from "react";
import "./Map.scss";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { getPlacesByBounds } from "../../../store/actions/search";
import popularPlace from "../../../assets/LandingPage/popularPlace.jpg";
import { FaStar } from "react-icons/fa";
import { calculateGrade } from "../../../helpers/calculateGrade";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const MapLeaflet = (props) => {
  const dispatch = useDispatch();
  const [lat, setLat] = useState(props.latitude);
  const [long, setLong] = useState(props.longitude);
  const mapRef = useRef(null);

  useEffect(() => {
    const bounds = mapRef.current.leafletElement.getBounds();

    dispatch(
      getPlacesByBounds(
        bounds._northEast.lat,
        bounds._northEast.lng,
        bounds._southWest.lat,
        bounds._southWest.lng
      )
    );
  }, [mapRef.current]);

  const onMoveEnd = (e) => {
    const bounds = e.target.getBounds();
    if (!props.term) {
      // get nearest locales only when search term is not exist
      dispatch(
        getPlacesByBounds(
          bounds._northEast.lat,
          bounds._northEast.lng,
          bounds._southWest.lat,
          bounds._southWest.lng
        )
      );
    }
  };

  useEffect(() => {
    props.latitude && !lat && setLat(parseFloat(props.latitude));
    props.longitude && !long && setLong(parseFloat(props.longitude));
    // eslint-disable-next-line
  }, [props.latitude, props.longitude]);

  const { markers } = useSelector((state) => state.search);

  return (
    <Map
      className='mapDisplay'
      center={[lat, long]}
      zoom={props.placeMap ? 20 : 20}
      zoomControl={props.zoomEnabled}
      ref={mapRef}
      onmoveend={onMoveEnd}
      onunload={onMoveEnd}>
      <TileLayer
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      {/* {props.placeMap ? (
        <Marker position={[60.1282, 18.6435]}>
        </Marker>
      ) : ( */}
      {!props.placeMap &&
        markers &&
        markers.map((marker) => (
          <Marker
            position={[
              parseFloat(marker.latitude),
              parseFloat(marker.longitude),
            ]}>
            <Popup>
              <Link to={`place/${marker.id}`}>
                <img src={popularPlace} alt='Search place' />
                <div className='popupContent'>
                  <h1>{marker.name}</h1>
                  <p>{marker.address}</p>
                  <div className='rate'>
                    <FaStar />
                    <span>
                      {marker.average_rating}{" "}
                      {calculateGrade(marker.average_rating)} (
                      {marker.rating_grades.length})
                    </span>
                  </div>
                </div>
              </Link>
            </Popup>
          </Marker>
        ))}
      {/* )} */}
    </Map>
  );
};

export default MapLeaflet;
