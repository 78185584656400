import React from 'react'
import './PrivacyAndTerms.scss'

import {Link} from 'react-router-dom'

const privacyAndTerms = (props) => {
  return (
    <div className="PrivacyAndTerms">
      <p>
        By clicking “{props.agree ? "Agree and continue" : "Create account"}” you agree to the{' '}
        <Link to="privacy-policy">privacy policy</Link> and{' '}
        <Link to="privacy-policy">terms of service</Link>.
      </p>
    </div>
  )
}

export default privacyAndTerms
