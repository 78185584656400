import React, { useState } from "react";
import "./PlaceNameMobile.scss";

import Stars from "react-stars-display";
import OpeningHours from "../OpeningHours/OpeningHours";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { HiMail } from "react-icons/hi";
import { FaDirections } from "react-icons/fa";
import { calculateGrade } from "../../../helpers/calculateGrade";
import { returnCharsName } from "../../../helpers/nameLength";
import { locale } from "../../../translate/spain";

const PlaceNameMobile = ({ place, handleFavoritePress }) => {
  const [showDescription, setShowDescription] = useState(false);
  return (
    <div className='PlaceNameMobile'>
      <div className='container'>
        <div className='flex'>
          <h1>{place && place.name}</h1>
        </div>
        <div className='rating'>
          <Stars
            stars={place && place.average_rating}
            size={25}
            fill='#FFC830'
          />
          <p>
            {place &&
              `${place.average_rating} ${calculateGrade(place.average_rating)}`}
          </p>
          <span>({place && place.reviews && place.reviews.length})</span>
        </div>
        <span>{place && place.address}</span>
      </div>
      <OpeningHours hours={place && place.opening_hours} />
      <div className='placeInfo'>
        <div className='infoBox' onClick={handleFavoritePress}>
          <div className='iconWrapper'>
            {place && place.is_favorite ? <FaHeart /> : <FaRegHeart />}
          </div>
          <p>{locale.place.favorite}</p>
        </div>
        <a className='infoBox' href={`tel:${place && place.phone}`}>
          <div className='iconWrapper'>
            <FaPhoneAlt />
          </div>
          <p>{locale.place.call}</p>
        </a>
        <a className='infoBox' href={`mailto:${place && place.email}`}>
          <div className='iconWrapper'>
            <HiMail />
          </div>
          <p>{locale.place.email}</p>
        </a>
        <div className='infoBox'>
          <div className='iconWrapper'>
            <FaDirections />
          </div>
          <p>{locale.place.directions}</p>
        </div>
      </div>
      <div className='description'>
        {place && place.description && (
          <p>
            {showDescription
              ? place.description
              : place.description.length > 125
              ? returnCharsName(place.description, 125)
              : place.description}
          </p>
        )}

        {!showDescription && (
          <button onClick={() => setShowDescription(true)}>
            {locale.showMore}
          </button>
        )}
      </div>
    </div>
  );
};

export default PlaceNameMobile;
