import React from "react";
import "./CheckEmail.scss";

import {Link} from 'react-router-dom'

import Bookybook from "../../../assets/Signup/Bookybook.png";
import Graphic from "../../../assets/Signup/Graphic5.png";

const CheckEmail = (props) => {
  return (
    <div className="CheckEmail">
      <Link to="/">
        <img src={Bookybook} alt="Bookybook" />
      </Link>

      <h1>Check your email</h1>
      <img src={Graphic} className="graphic" alt="Check email" />
      <p>
        An email has been sent to *email-that-was-added” to verify that the
        email belongs to you.
      </p>
    </div>
  );
};

export default CheckEmail;
