import React, { useEffect, useState } from "react";
import "./BookingPlace.scss";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { BsArrowRepeat } from "react-icons/bs";

import PageLayout from "../../../hoc/PageLayout/PageLayout";
import StickyHeader from "../../../components/Utils/StickyHeader/StickyHeader";
import Ticket from "../../../components/Booking/Ticket/Ticket";
import AboutUsAndDetails from "../../../components/Place/AboutUsAndDetails/AboutUsAndDetails";

import { getPlace } from "../../../store/actions/place";
import { cancelAppointment } from "../../../store/actions/booking";

import { locale } from "../../../translate/spain";

const BookingPlace = (props) => {
  const dispatch = useDispatch();
  const { type, placeId, id } = useParams();
  const { place } = useSelector((state) => state.place);
  const activeBookings = useSelector((state) => state.booking.active);
  const nonActiveBookings = useSelector((state) => state.booking.nonActive);
  const [booking, setBooking] = useState(null);

  useEffect(() => {
    dispatch(getPlace(placeId));
    if (type === "upcoming") {
      const found = activeBookings.find((elem) => elem.id === parseInt(id));
      setBooking(found);
    } else {
      const found = nonActiveBookings.find((elem) => elem.id === parseInt(id));
      setBooking(found);
    }
    // eslint-disable-next-line
  }, []);

  const cancelAppointmentHandler = () => {
    dispatch(cancelAppointment(booking.cancellation_code, props.history));
  };

  return (
    <PageLayout>
      <div className='BookingPlace'>
        <StickyHeader title={locale.myBookings.title} back exit />

        <div className='main'>
          <div className='ticket'>
            <Ticket
              bigTicket
              place={place}
              date={booking ? booking.date : null}
              time={booking ? booking.from : null}
              worker={booking ? booking.employee : null}
              service={{
                service: booking ? booking.service.name : "Hairdress",
                duration: booking ? booking.service.duration : 30,
                price: booking ? booking.service.price : 30,
                service_id: booking ? booking.service.id : 1,
              }}
              paymentMethod={true}
            />
            {type === "upcoming" ? (
              <button
                className='book upcoming'
                onClick={cancelAppointmentHandler}>
                <BsArrowRepeat />
                <span>{locale.cancelBooking.heading}</span>
              </button>
            ) : (
              <button
                className='book'
                onClick={() =>
                  props.history.push(
                    `/book/${place.id}?service_id=${booking.service.id}&service_name=${booking.service.name}&duration=${booking.service.duration}&price=${booking.service.price}`
                  )
                }>
                <BsArrowRepeat />
                <span>Book again</span>
              </button>
            )}
          </div>
          {place && <AboutUsAndDetails place={place} />}
        </div>
      </div>
    </PageLayout>
  );
};

export default BookingPlace;
