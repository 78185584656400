import React from "react";
import "./WholeLogin.scss";

import { Link } from "react-router-dom";

// import LoginWith from "../../../components/Login/LoginWith/LoginWith";
import LoginForm from "../../../components/Login/LoginForm/LoginForm";
import { locale } from "../../../translate/spain";

// import Google from "../../../assets/Signup/Google.png";
// import Facebook from "../../../assets/Signup/Facebook.png";

const wholeLogin = (props) => {
  return (
    <div className="WholeLogin">
      <LoginForm {...props} />
      {/* <p className="or">OR</p>
      <LoginWith img={Google} name="Google" />
      <LoginWith img={Facebook} name="Facebook" /> */}

      <div className="noAccount">
        <Link to="/signup">{locale.auth.dontHaveAcc}</Link>
        {/* <p>{locale.auth.dontHaveAcc} </p> */}
        {props.book ? (
          <span onClick={props.book} className="signup">
            {locale.auth.signupBtn}
          </span>
        ) : (
          <Link to="/signup">{locale.auth.signupBtn}</Link>
        )}
      </div>
    </div>
  );
};

export default wholeLogin;
