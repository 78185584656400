import React, { useState } from "react";
import "./BookingNotes.scss";
import { HiArrowLeft } from "react-icons/hi";
import { locale } from "../../../translate/spain";

const BookingNotes = (props) => {
  const [notes, setNotes] = useState(props.value);

  const handleChange = (val) => {
    setNotes(val);
  };

  return (
    <div className="BookingNotes">
      <div className="header">
        <div className="back" onClick={props.onSave}>
          <HiArrowLeft />
        </div>
        <h3>{locale.book.bookingNotesLabel}</h3>
      </div>
      <div className="notesContent">
        <p>{locale.book.bookingNotesDesc}</p>
        <textarea
          placeholder="Write booking notes..."
          value={notes}
          onChange={(e) => handleChange(e.target.value)}
        />
        <div
          className="termsBottom"
          onClick={() => {
            props.onSave();
            props.onChange(notes);
          }}
        >
          <button>{locale.save}</button>
        </div>
      </div>
    </div>
  );
};

export default BookingNotes;
