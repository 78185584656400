import React from "react";
import "./Login.scss";

import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { getProfile } from "../../../store/actions/account";

import Bookybook from "../../../assets/layout/Bookybook.png";
import WholeLogin from "../../../components/Login/WholeLogin/WholeLogin";
import { BiArrowBack } from "react-icons/bi";
import { locale } from "../../../translate/spain";

const Login = (props) => {
  const history = useHistory();
  return (
    <div className="Login">
      <div className="topArea">
        <div className="back" onClick={() => history.goBack()}>
          <BiArrowBack />
        </div>
        <Link to="/">
          <img className="logo" src={Bookybook} alt="Bookybook" />
        </Link>
      </div>
      <div className="loginContent">
        <h1>{locale.auth.loginHeading}</h1>
        <WholeLogin />
      </div>
      <div className="loginContent professionals">
        <h1>{locale.auth.loginForProfessionals}</h1>
        <p>{locale.auth.loginProfDescription}</p>
        <a href={process.env.REACT_APP_B2B_LINK_LOGIN}>
          {locale.auth.bussinesLoginPage}
        </a>
      </div>
      <span className="allRights">© {locale.footer.copyRight}</span>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.account.profile,
  };
};
export default connect(mapStateToProps, { getProfile })(Login);
