import React, { useEffect, useState } from "react";
import "./BookReview.scss";
import { MdCheck } from "react-icons/md";
import Input from "../../Signup/SignupInput/SignupInput";
import { calculateCancellation } from "../../../helpers/calculateCancellation";
import Ticket from "../../../components/Booking/Ticket/Ticket";
import { BsChevronRight } from "react-icons/bs";
import { CgNotes } from "react-icons/cg";
import { BiCheckCircle } from "react-icons/bi";
import BookingNotes from "../../Booking/BookingNotes/BookingNotes";
import { locale } from "../../../translate/spain";

const BookReview = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [otherValue, setOtherValue] = useState("");

  const setBookingNotesHandler = (property) => {
    props.setBookingNotes(property);
  };
  const setAddressHandler = (property) => {
    setAddress(property);
    props.setRequiredInfo((p) => ({ ...p, address: property }));
  };
  const setFirstNameHandler = (property) => {
    setFirstName(property);
    props.setRequiredInfo((p) => ({ ...p, first_name: property }));
  };
  const setLastNameHandler = (property) => {
    setLastName(property);
    props.setRequiredInfo((p) => ({ ...p, last_name: property }));
  };
  const setEmailHandler = (property) => {
    setEmail(property);
    props.setRequiredInfo((p) => ({ ...p, email: property }));
  };
  const setPhoneHandler = (property) => {
    setPhone(property);
    props.setRequiredInfo((p) => ({ ...p, phone: property }));
  };
  const setPostalCodeHandler = (property) => {
    setPostalCode(property);
    props.setRequiredInfo((p) => ({ ...p, zip_code: property }));
  };
  const setCityHandler = (property) => {
    setCity(property);
    props.setRequiredInfo((p) => ({ ...p, city: property }));
  };
  const setOtherHandler = (property) => {
    setOtherValue(property);
    props.setRequiredInfo((p) => ({ ...p, [props.otherKey]: property }));
  };
  useEffect(() => {
    const otherTemp = props.worker.calendar.required_info.find(
      (elem) =>
        elem !== "first_name" &&
        elem !== "last_name" &&
        elem !== "email" &&
        elem !== "zip_code" &&
        elem !== "address" &&
        elem !== "city" &&
        elem !== "phone"
    );
    if (otherTemp) {
      props.setOtherKey(otherTemp);
      props.setRequiredInfo({
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone: phone,
        address: address,
        zip_code: postalCode,
        city: city,
        [otherTemp]: otherValue,
      });
    } else {
      delete props.required_info[[props.otherKey]];
    }
    if (
      !props.worker.calendar.required_info.some((elem) => elem === "first_name")
    ) {
      delete props.required_info["first_name"];
    }
    if (
      !props.worker.calendar.required_info.some((elem) => elem === "last_name")
    ) {
      delete props.required_info["last_name"];
    }
    if (!props.worker.calendar.required_info.some((elem) => elem === "email")) {
      delete props.required_info["email"];
    }
    if (!props.worker.calendar.required_info.some((elem) => elem === "phone")) {
      delete props.required_info["phone"];
    }
    if (
      !props.worker.calendar.required_info.some((elem) => elem === "address")
    ) {
      delete props.required_info["address"];
    }
    if (
      !props.worker.calendar.required_info.some((elem) => elem === "zip_code")
    ) {
      delete props.required_info["zip_code"];
    }
    if (!props.worker.calendar.required_info.some((elem) => elem === "city")) {
      delete props.required_info["city"];
    }
    // eslint-disable-next-line
  }, [props.worker.calendar.required_info]);

  const snakeToCamel = (str) =>
    str.replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace("-", " ").replace("_", " ")
    );

  return (
    <>
      <div className="BookReview">
        <div className="loggedIn">
          <p>
            {locale.book.loggedInAs} <span>{props.name}</span>
          </p>
        </div>

        <div className="main">
          <div className="receipt mobile">
            <Ticket
              place={props.place}
              service={props.service}
              worker={props.worker}
              date={props.date}
              time={props.time}
              paymentMethod
            />
            <div className="bottom"></div>
          </div>
          <div className="cancelationPolicy desktop">
            <label>{locale.book.cancellationPolicy}</label>
            <p>
              {locale.book.cancelForFree}{" "}
              <b>
                {calculateCancellation(
                  props.worker.calendar.cancellation_margin
                )}
              </b>{" "}
              {locale.book.ahead}
            </p>
          </div>
          <div
            className={"addBookingNotes" + (props.bookingNotes ? " added" : "")}
            onClick={() => props.setNotesOpen(true)}>
            <div className="left">
              <div
                className="notesIcon"
                style={{
                  borderColor: !props.bookingNotes ? "#1A73E8" : "#253342",
                }}>
                <CgNotes
                  style={{
                    color: !props.bookingNotes ? "#1A73E8" : "#253342",
                  }}
                />
              </div>
              <p
                style={{
                  color: !props.bookingNotes ? "#1A73E8" : "#253342",
                }}>
                {props.bookingNotes
                  ? locale.book.bookingNotes
                  : locale.book.bookingNotesLabel}
              </p>
              {props.bookingNotes && <BiCheckCircle />}
            </div>
            <BsChevronRight />
          </div>

          <div className="desktopNotes">
            <Input
              label={locale.book.bookingNotesLabel}
              description={locale.book.bookingNotesDesc}
              textarea
              placeholder=" "
              value={props.bookingNotes}
              onChange={setBookingNotesHandler}
            />
          </div>
          {props.worker.calendar.required_info.some(
            (elem) => elem === "first_name"
          ) && (
            <Input
              label={locale.auth.firstNameLabel}
              placeholder=" "
              value={firstName}
              onChange={setFirstNameHandler}
            />
          )}
          {props.worker.calendar.required_info.some(
            (elem) => elem === "last_name"
          ) && (
            <Input
              label={locale.auth.lastNameLabel}
              placeholder=" "
              value={lastName}
              onChange={setLastNameHandler}
            />
          )}
          {props.worker.calendar.required_info.some(
            (elem) => elem === "email"
          ) && (
            <Input
              label={locale.auth.emailLabel}
              placeholder=" "
              value={email}
              onChange={setEmailHandler}
            />
          )}
          {props.worker.calendar.required_info.some(
            (elem) => elem === "phone"
          ) && (
            <Input
              label={locale.auth.mobileNumberLabel}
              placeholder=" "
              value={phone}
              onChange={setPhoneHandler}
            />
          )}
          {props.worker.calendar.required_info.some(
            (elem) => elem === "address"
          ) && (
            <Input
              label={locale.auth.addressLabel}
              placeholder=" "
              value={address}
              onChange={setAddressHandler}
            />
          )}
          {props.worker.calendar.required_info.some(
            (elem) => elem === "zip_code"
          ) && (
            <Input
              label={locale.auth.postalCode}
              placeholder=" "
              value={postalCode}
              onChange={setPostalCodeHandler}
            />
          )}
          {props.worker.calendar.required_info.some(
            (elem) => elem === "city"
          ) && (
            <Input
              label={locale.auth.city}
              placeholder=" "
              value={city}
              onChange={setCityHandler}
            />
          )}
          {props.worker.calendar.required_info.map((elem) => {
            if (
              elem !== "first_name" &&
              elem !== "last_name" &&
              elem !== "email" &&
              elem !== "zip_code" &&
              elem !== "address" &&
              elem !== "city" &&
              elem !== "phone"
            ) {
              return (
                <Input
                  label={snakeToCamel(elem)}
                  value={otherValue}
                  onChange={setOtherHandler}
                  placeholder=" "
                />
              );
            } else {
              return null;
            }
          })}

          <div className="cancelationPolicy mobile">
            <label>{locale.book.cancellationPolicy}</label>
            <p>
              {locale.book.cancelForFree}{" "}
              <b>
                {calculateCancellation(
                  props.worker.calendar.cancellation_margin
                )}
              </b>{" "}
              {locale.book.ahead}
            </p>
          </div>

          {props.worker.calendar.toc && (
            <div className="terms">
              <div
                className={"checkbox" + (props.agree ? " active" : "")}
                onClick={() => props.setAgree((p) => !p)}>
                <MdCheck />
              </div>
              <p>
                {locale.book.iAccept}{" "}
                <button
                  style={{ textTransform: "lowercase" }}
                  onClick={() => props.setTermsOpen(true)}>
                  {locale.book.termsAndConditions}
                </button>
              </p>
            </div>
          )}
        </div>
      </div>
      {props.notesOpen && (
        <BookingNotes
          value={props.bookingNotes}
          onChange={setBookingNotesHandler}
          onSave={() => props.setNotesOpen(false)}
        />
      )}
    </>
  );
};

export default BookReview;
