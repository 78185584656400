import React, { useEffect, useState } from "react";
import "./Navigation.scss";
import { Link, useHistory } from "react-router-dom";
// import Bookybook from "../../../assets/layout/Bookybook.png";
import axios from "axios";
import { connect, useSelector, useDispatch } from "react-redux";
import { FaRegCalendarAlt } from "react-icons/fa";
import { BsChevronRight } from "react-icons/bs";
import { AiOutlineSetting, AiOutlineCalendar } from "react-icons/ai";
import { BiLogOut } from "react-icons/bi";
import { FiHeart } from "react-icons/fi";
import { GrSearch, GrLocation } from "react-icons/gr";
import { MdClose } from "react-icons/md";
import Suggestion from "../../Search/Suggestion/Suggestion";
import { searchAutocomplete } from "../../../store/actions/search";
import {
  locationAutoComplete,
  currentAutoLocation,
} from "../../../store/actions/locations";

import ProfileOptions from "./ProfileOptions/ProfileOptions";
import { logout } from "../../../store/actions/account";

//translate
import { locale } from "../../../translate/spain";

let searchTimeout = null;
const Navigation = (props) => {
  const dispatch = useDispatch();
  const authorized = useSelector((state) => state.account.authorized);
  let links = null;
  let mobileLinks = null;
  const serviceTypes = useSelector((state) => state.search.serviceTypes);
  const places = useSelector((state) => state.search.places);

  const [serviceSuggestions, setServiceSuggestions] = useState([]);
  const [service, setService] = useState(props.term);
  const [area, setArea] = useState(
    props.location ? props.location : locale.searchPage.nearMe
  );
  const [areaSuggestions, setAreaSuggestions] = useState([]);
  const [locationCords, setLocationCords] = useState({});
  const history = useHistory();

  const deleteService = () => {
    // e.preventDefault();
    setService("");
  };

  const deleteArea = () => {
    // e.preventDefault();
    setArea("");
  };

  const handleLogout = (e) => {
    dispatch(logout());
    props.toggleNavigation(e);
  };

  useEffect(() => {
    setService(props.term);
    setArea(props.location ? props.location : locale.searchPage.nearMe);
  }, [props.term, props.location]);

  const firstInputClick = (e) => {
    e.stopPropagation();
    props.handleSecondInputClick(false);
    props.handleFirstInputClick(true);
  };

  const secondInputClick = (e) => {
    e.stopPropagation();
    props.handleFirstInputClick(false);
    props.handleSecondInputClick(true);
  };

  const setServiceHandler = (value) => {
    setService(value);
    if (value.length >= 2) {
      dispatch(searchAutocomplete(value));
    }
  };

  const locationSuggestion = (property) => {
    clearTimeout(searchTimeout);

    setArea(property);

    let mapType = process.env.REACT_APP_AUTOCOMPLETE_MAPS;

    searchTimeout = setTimeout(async () => {
      const autoSuggestionRes = await locationAutoComplete(mapType, property);
      setAreaSuggestions(autoSuggestionRes);
    }, 600);
  };

  const autoLoactionSuggestion = (lat, lon) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          let mapType = process.env.REACT_APP_AUTOCOMPLETE_MAPS;
          const currentSuggestionRes = await currentAutoLocation(mapType, pos);
          setArea(currentSuggestionRes);
          setLocationCords({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          alert(error.message);
        }
      );
    }
  };

  useEffect(() => {
    setServiceSuggestions(serviceTypes.slice(0, 4).concat(places.slice(0, 4)));
  }, [serviceTypes, places]);

  links = (
    <>
      <div className='desktop'>
        {authorized ? (
          <Link to='/bookings' className='booking'>
            <FaRegCalendarAlt />
            {locale.navbar.bookings}
          </Link>
        ) : (
          props.landingPage && (
            <>
              <Link to='/login/auth'>{locale.navbar.login}</Link>
              <Link to='/signup'>{locale.navbar.signup}</Link>
              <a href={process.env.REACT_APP_B2B_LINK} className='button'>
                {locale.navbar.registerBusiness}
              </a>
            </>
          )
        )}
        {/* {!authorized && (
          <a href={process.env.REACT_APP_B2B_LINK} className="button">
            For buisness
          </a>
        )} */}
        <ProfileOptions
          isOpen={props.navigation}
          toggleNavigation={props.toggleNavigation}
        />
      </div>

      <div className='mobile'>
        {!props.navigation &&
          (!authorized ? (
            <Link to='/login/auth'>Acceder</Link>
          ) : (
            <Link to='/bookings' className='booking'>
              <FaRegCalendarAlt />
              {locale.navbar.myReservations}
            </Link>
          ))}
        <div
          className={"hamburger" + (props.navigation ? " active" : "")}
          onClick={(e) => props.toggleNavigation(e)}>
          <div className='line'></div>
          <div className='line'></div>
          <div className='line'></div>
        </div>
      </div>
    </>
  );

  mobileLinks = (
    <>
      <div className='container'>
        <Link className='mobileLink' to='/login/auth'>
          <p>{locale.navbar.login}</p>
          <BsChevronRight />
        </Link>
        <Link className='mobileLink' to='/signup'>
          <p>{locale.navbar.signup}</p>
          <BsChevronRight />
        </Link>
        <Link className='mobileLink' to='/cancel-booking'>
          <p>{locale.navbar.cancelBooking}</p>
          <BsChevronRight />
        </Link>
        <div className='forB2b'>
          <p style={{ textTransform: "uppercase" }}>
            {locale.navbar.forProfesionals}
          </p>
        </div>
        <a className='mobileLink' href={process.env.REACT_APP_B2B_LINK}>
          <p>{locale.navbar.forProfesionals}</p>
          <BsChevronRight />
        </a>
        <a className='mobileLink' href={process.env.REACT_APP_LINK_LOGIN}>
          <p>{locale.navbar.login}</p>
          <BsChevronRight />
        </a>
        <a className='mobileLink' href={process.env.REACT_APP_B2B_LINK_PRICE}>
          <p>{locale.navbar.signup}</p>
          <BsChevronRight />
        </a>
      </div>
    </>
  );

  if (authorized) {
    mobileLinks = (
      <>
        <div className='container'>
          <Link className='mobileLink' to='/bookings'>
            <div className='linkWithIcon'>
              <AiOutlineCalendar />
              <p>{locale.navbar.myReservations}</p>
            </div>
            <BsChevronRight />
          </Link>
          <Link className='mobileLink' to='/favourite'>
            <div className='linkWithIcon'>
              <FiHeart />
              <p>{locale.navbar.myFavorites}</p>
            </div>
            <BsChevronRight />
          </Link>
          <div className='mobileLink'>
            <Link to='/cancel-booking'>{locale.navbar.cancelBooking}</Link>
          </div>
          <div
            className='mobileLink'
            href='!#'
            onClick={(e) => handleLogout(e)}>
            <div className='linkWithIcon'>
              <BiLogOut />
              <p>{locale.navbar.logout}</p>
            </div>
            <BsChevronRight />
          </div>
          <Link className='mobileLink' to='/profile'>
            <div className='linkWithIcon'>
              <AiOutlineSetting />
              <p>{locale.navbar.profile}</p>
            </div>
            <BsChevronRight />
          </Link>
          <div className='forB2b'>
            <p style={{ textTransform: "uppercase" }}>
              {locale.navbar.forProfesionals}
            </p>
          </div>
          <a className='mobileLink' href={process.env.REACT_APP_B2B_LINK}>
            <p>{locale.navbar.forProfesionals}</p>
            <BsChevronRight />
          </a>
          <a className='mobileLink' href={process.env.REACT_APP_LINK_LOGIN}>
            <p>{locale.navbar.login}</p>
            <BsChevronRight />
          </a>
          <a className='mobileLink' href={process.env.REACT_APP_B2B_LINK_PRICE}>
            <p>{locale.navbar.price}</p>
            <BsChevronRight />
          </a>
        </div>
        {/* <a href="/" className="button green">
          Book treatment
        </a> */}
      </>
    );
  }

  return (
    <div
      className={
        "Navigation" +
        (props.main && !props.navigation ? " main" : "") +
        (props.searchResults ? " searchResults" : "") +
        (props.place ? " place" : "")
      }
      onClick={() => {
        if (props.searchForm) {
          props.handleFirstInputClick(false);
          props.handleSecondInputClick(false);
        }
      }}>
      <div className='container'>
        <div className='logo'>
          <Link to='/'>
            <div className='logo-img' />
          </Link>
        </div>
        {props.searchForm && (
          <form
            className={
              "search" +
              (props.firstInputFocus || props.secondInputFocus ? " active" : "")
            }
            action='/search'
            autoComplete='off'>
            <input type='hidden' value='' name='category' />
            <input
              type='hidden'
              value={locationCords.latitude}
              name='latitude'
            />
            <input
              type='hidden'
              value={locationCords.longitude}
              name='longitude'
            />
            <input type='hidden' value='' name='filter' />

            <div className='input'>
              <input
                type='text'
                placeholder={
                  locale.landingPage.discover.searchServicePlaceholder
                }
                name='term'
                value={service ? service : ""}
                className={props.firstInputFocus ? "focused" : ""}
                onChange={(e) => setServiceHandler(e.target.value)}
                onClick={(e) => firstInputClick(e)}
                onFocus={(e) => firstInputClick(e)}
              />
              <div className='inputIcon'>
                <GrSearch size={18} />
              </div>
              {props.firstInputFocus && service && (
                <div className='close' onClick={deleteService}>
                  <MdClose />
                </div>
              )}

              {props.firstInputFocus &&
                service.length >= 2 &&
                serviceSuggestions.length > 0 && (
                  <Suggestion
                    suggestions={serviceSuggestions}
                    onSuggestionClick={(elem) => {
                      if (elem.public_profile) {
                        setServiceSuggestions([]);
                        history.push(`/place/${elem.id}?term=${elem.name}`);
                      } else {
                        setService(elem.name);
                        dispatch(searchAutocomplete(elem.name));
                        props.handleFirstInputClick(false);
                      }
                    }}
                    nav
                    autoLoactionSuggestion={autoLoactionSuggestion}
                  />
                )}
            </div>
            <div className='input'>
              <input
                type='text'
                placeholder={locale.landingPage.discover.searchCityPlaceholder}
                name='location'
                value={area ? area : ""}
                className={props.secondInputFocus ? "focused" : ""}
                onChange={(e) => locationSuggestion(e.target.value)}
                onClick={(e) => secondInputClick(e)}
                onFocus={(e) => secondInputClick(e)}
              />
              <div className='inputIcon'>
                <GrLocation size={18} />
              </div>
              {props.secondInputFocus && area && (
                <div className='close' onClick={deleteArea}>
                  <MdClose />
                </div>
              )}

              {props.secondInputFocus &&
                (area && areaSuggestions.length > 0 ? (
                  <Suggestion
                    location
                    suggestions={areaSuggestions}
                    onSuggestionClick={(elem) => {
                      setLocationCords(elem.address);
                      setArea(elem.name);
                      props.handleSecondInputClick(false);
                    }}
                    nav
                    autoLoactionSuggestion={autoLoactionSuggestion}
                  />
                ) : (
                  <Suggestion
                    location
                    suggestions={[]}
                    onSuggestionClick={(elem) => {
                      setArea(elem);
                      props.handleSecondInputClick(false);
                    }}
                    nav
                    nearby
                    autoLoactionSuggestion={autoLoactionSuggestion}
                  />
                ))}
            </div>
            <button className='searchButton'>
              <GrSearch />
              <span>{locale.landingPage.discover.searchBtn}</span>
            </button>
          </form>
        )}

        {!props.loader ? (
          <React.Fragment>
            <div className='links'>{links}</div>
            <div
              className={
                "mobile-nav" + (props.navigation ? " modal-open" : "")
              }>
              {mobileLinks}
            </div>
          </React.Fragment>
        ) : null}
      </div>
    </div>
  );
};

export default connect(null, { logout })(Navigation);
