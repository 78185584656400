import React from "react";
import "./Ticket.scss";

import PlaceNameBooking from "../PlaceNameBooking/PlaceNameBooking";
import BookingDate from "../BookingDate/BookingDate";
import BookingCategories from "../BookingCategories/BookingCategories";
import { locale } from "../../../translate/spain";

const Ticket = (props) => {
  return (
    <div className="Ticket">
      {props.date && props.time && (
        <>
          <BookingDate
            calendar={props.ticket}
            date={props.date}
            time={props.time}
          />
          <hr />
        </>
      )}
      <PlaceNameBooking
        favorites={props.ticket}
        name={props.place && props.place.name}
        address={props.place && props.place.address}
      />

      {/* <hr /> */}

      <BookingCategories
        bigTicket={props.bigTicket}
        service={props.service && props.service.service ? props.service : null}
        worker={props.worker ? props.worker : null}
      />

      <div className="total">
        <p>{locale.myBookings.totalAmount}</p>
        <p>
          {locale.currency}{" "}
          {props.service && props.service.price ? props.service.price : 0}
        </p>
      </div>

      {props.paymentMethod && (
        <>
          <hr />
          <div className="method">
            <p>{locale.myBookings.paymentMethod}</p>
          </div>
        </>
      )}
    </div>
  );
};

export default Ticket;
