import React from "react";
import SinglePopularPlace from "../SinglePopularPlace/SinglePopularPlace";
import "./PopularPlaces.scss";

const PopularPlaces = ({ recomendedPlaces }) => {
  return (
    <div className='PopularPlaces'>
      {recomendedPlaces &&
        recomendedPlaces?.map((place) => (
          <SinglePopularPlace key={place.id} place={place} />
        ))}
    </div>
  );
};

export default PopularPlaces;
