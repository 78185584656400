import React from "react";
import "./ProfilePicture.scss";

import { connect } from "react-redux";
import { uploadAvatar, deleteAvatar } from "../../../store/actions/account";

import { FaRegUser } from "react-icons/fa";
import { locale } from "../../../translate/spain";

const ProfilePicture = (props) => {
  const onImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0];
      const fd = new FormData();
      fd.append("avatar", img);
      let sizeInMb = +(e.target.files[0].size / (1024 * 1024)).toFixed(2); // convert bytes to mb

      if (sizeInMb < 1) {
        props.uploadAvatar(fd);
        return;
      }
      alert("You cannot upload image larger than 1 MB");
    }
  };

  const deleteAvatar = () => {
    props.deleteAvatar();
  };

  return (
    <div className={"ProfilePicture" + (props.settings ? " settings" : "")}>
      <div
        className={
          "profile" + (props.avatar && props.avatar.url ? " image" : "")
        }>
        {props.avatar && props.avatar.url ? (
          <img src={props.avatar && props.avatar.url} alt="Test" />
        ) : (
          <FaRegUser />
        )}
      </div>
      <div className="description">
        {/* <label>Profile picture (optional, but highly recommended)</label> */}
        <p>{locale.profile.photoDescription}</p>
        <div className="inline">
          <input type="file" onChange={onImageChange} />
          <button>{locale.profile.uploadPicture}</button>
          <button onClick={deleteAvatar}>{locale.profile.deletePicture}</button>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { uploadAvatar, deleteAvatar })(ProfilePicture);
