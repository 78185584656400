import React from "react";
import "./PlaceNameBooking.scss";
import { BiStore } from "react-icons/bi";

const placeName = (props) => {
  return (
    <div className="PlaceNameBooking">
      <BiStore />
      <div className="title">
        <div className="name">
          <h4>{props.name}</h4>
          <p>{props.address}</p>
        </div>

        {/* {props.favorites && <button>Add to favorites</button>} */}
      </div>
    </div>
  );
};

export default placeName;
