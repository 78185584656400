import React from "react";
import "./Terms.scss";
import { HiArrowLeft } from "react-icons/hi";
import { locale } from "../../../translate/spain";

const Terms = (props) => {
  return (
    <>
      <div className="overlay" onClick={props.back} />
      <div className="Terms">
        <div className="header">
          <div className="back" onClick={props.back}>
            <HiArrowLeft />
          </div>
          <h3>{locale.book.termsAndConditions}</h3>
        </div>
        <div className="notesContent">
          <p>{props.toc}</p>
          <div className="termsBottom" onClick={props.onSave}>
            <button>
              {locale.book.iAccept} {locale.book.termsAndConditions}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Terms;
