import React from 'react'
import './LoadingScreen.scss'

import { connect } from 'react-redux'

const LoadingScreen = (props) => {
  if (!props.loader) {
    return null
  }

  return (
    <div className="LoadingScreen">
      <div id="loader">
        <div id="shadow"></div>
        <div id="box"></div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loader: state.loader,
  }
}

export default connect(mapStateToProps)(LoadingScreen)
