import React, { useState } from "react";
import "./ChangePassword.scss";

import {connect} from 'react-redux';
import {changePassword} from '../../../store/actions/account';

import { MdClose } from "react-icons/md";

import Input from "../../../components/Signup/SignupInput/SignupInput";


const ChangePassword = (props) => { 
  const [changePassword, setChangePassword] = useState(true);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const setCurrentPasswordHandler = (property) => {
    setCurrentPassword(property);
  };

  const setNewPasswordHandler = (property) => {
    setNewPassword(property);
  };

  const changePasswordHandler = () => {
    props.changePassword(currentPassword,newPassword,props.close);
  }

  return (
    <React.Fragment>
      {changePassword ? (
        <div className="ChangePassword">
          <div className="overlay" onClick={props.close} />
          <div className="slider">
            <div className="header">
              <p>{props.translate.title}</p>
              <MdClose onClick={props.close} />
            </div>

            <div className="container">
              <Input
                label={props.translate.currentPassword}
                placeholder={props.translate.currentPlaceholder}
                type={showPassword ? "text" : "password"}
                value={currentPassword}
                button={{
                  text: props.translate.forgotPassword,
                  action: () => setChangePassword(false),
                }}
                onChange={setCurrentPasswordHandler}
              />
              <Input
                label={props.translate.newPassword}
                placeholder={props.translate.newPlaceholder}
                type={showPassword ? "text" : "password"}
                value={newPassword}
                button={{
                  text: showPassword ? props.translate.hidePassword : props.translate.showPassword,
                  action: () => setShowPassword((p) => !p),
                }}
                onChange={setNewPasswordHandler}
              />

              <div className="buttons">
                <button onClick={changePasswordHandler}>{props.translate.confirmButton}</button>
                <button onClick={props.close}>{props.translate.declineButton}</button>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {!changePassword ? (
        <div className="ForgotPassword">
          <div className="overlay" onClick={props.close} />
          <div className="slider">
            <div className="header">
              <p>{props.translate.forgotPassword}</p>
              <MdClose onClick={props.close} />
            </div>

            <div className="container">
              <p>
                Informacije o resetovanju lozinke će biti poslate na vašu email adresu,{" "}
                <b>{props.email}</b>. Kliknite na Resetuj lozinku da biste nastavili.
              </p>

              <div className="buttons">
                <button>{props.translate.confirmButton}</button>
                <button onClick={() => setChangePassword(true)}>{props.translate.declineButton}</button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default connect(null,{changePassword})(ChangePassword);
