import * as actionTypes from "./actionTypes";
import axios from "../../utility/axios/axios";
import { setPlace } from "./place";

import { startLoading, finishLoading } from "./loader";

export const setFavorites = (payload) => {
  return {
    type: actionTypes.SET_FAVORITES,
    payload,
  };
};

export const getFavorites = () => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .get("/api/favorites")
      .then(({ data }) => {
        dispatch(setFavorites(data.data));
        dispatch(finishLoading());
      })
      .catch((error) => {
        console.log(error);
        dispatch(finishLoading());
      });
  };
};

export const addToFavorites = (id) => {
  return (dispatch) => {
    // dispatch(startLoading());
    axios
      .post("/api/favorites/add", { place_id: id })
      .then(({ data }) => {
        dispatch(setPlace(data.data));
        // dispatch(finishLoading());
      })
      .catch((error) => {
        console.log(error);
        // dispatch(finishLoading());
      });
  };
};

export const removeFromFavorites = (id) => {
  return (dispatch) => {
    // dispatch(startLoading());
    axios
      .post("/api/favorites/remove", { place_id: id })
      .then(({ data }) => {
        dispatch(setPlace(data.data));
        // dispatch(finishLoading());
      })
      .catch((error) => {
        console.log(error);
        // dispatch(finishLoading());
      });
  };
};
