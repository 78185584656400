import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getCanceledAppointment,
  cancelAppointment,
} from "../../../store/actions/booking";
import PageLayout from "../../../hoc/PageLayout/PageLayout";
import CancellationModal from "../../../components/Appointments/CancellationModal/CancellationModal";
import "./CanceledAppointment.scss";

const CanceledAppointment = ({ history, match }) => {
  const dispatch = useDispatch();
  const [appointmentInfo, setAppointmentInfo] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    dispatch(
      getCanceledAppointment(match.params.id, (data) => {
        setIsModalOpen(true);
        setAppointmentInfo(data);
      })
    );
  }, [match.params.id]);

  const confirmCancellation = () => {
    dispatch(
      cancelAppointment(match.params.id, null, () => {
        setIsModalOpen(false);
      })
    );
  };

  return (
    <PageLayout footer>
      {isModalOpen && appointmentInfo && (
        <CancellationModal
          date={appointmentInfo.date_of_booking}
          confirmCancellation={confirmCancellation}
          discardCancellation={() => history.push("/")}
        />
      )}
      {!isModalOpen && appointmentInfo && (
        <div className='CanceledAppointment'>
          <div className='info'>
            <h1>Potvrda otkazivanja</h1>
            <p>{appointmentInfo.date_of_creation}</p>

            <div className='info_single'>
              <p className='title'>Usluga</p>
              <p className='data'>{appointmentInfo.service_name}</p>
            </div>
            <div className='info_single'>
              <p className='title'>Datum i vreme usluge</p>
              <p className='data'>{appointmentInfo.date_of_booking}</p>
            </div>
            <div className='info_single'>
              <p className='title'>Radnik</p>
              <p className='data'>{appointmentInfo.staff_name}</p>
            </div>
            <div className='info_single'>
              <p className='title'>Mesto</p>
              <p className='data'>
                {appointmentInfo.place_name}-{appointmentInfo.place_address},
                {appointmentInfo.place_city}
              </p>
            </div>
            <div className='info_single'>
              <p className='title'>Da li imaš pitanja? 💭</p>
              <p className='data'>
                Za pitanja o rezervaciji, kontaktirajte{" "}
                {appointmentInfo.place_name} na {appointmentInfo.place_phone}{" "}
                što je pre moguće.
              </p>
              <button onClick={() => history.push("/")}>
                Početna stranica
              </button>
            </div>
          </div>
        </div>
      )}
    </PageLayout>
  );
};

export default CanceledAppointment;
