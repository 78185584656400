import React from "react";
import "./Stores.scss";

import { Link } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import StoreSwiper from "../StoreSwiper/StoreSwiper";
import { calculateGrade } from "../../../helpers/calculateGrade";
import { locale } from "../../../translate/spain";

const stores = (props) => {
  const storesList = props.stores.map((store, index) => (
    <Link
      to={`/place/${store.id}?term=${props.type}&location=${props.city}`}
      className={"store" + (props.search ? " search" : "")}
      key={index}>
      <StoreSwiper logo={store.logo} />

      <div className="containerStore">
        <div className="description">
          <div className="name">
            <h5>{store.name}</h5>
          </div>

          <div className="stars">
            <FaStar />
            <p>
              {store.average_rating
                ? `${store.average_rating} ${calculateGrade(
                    store.average_rating
                  )}`
                : `${store.average_rating} ${calculateGrade(0)}`}
            </p>
            <span>
              (
              {store.reviews
                ? store.reviews.length + ` ${locale.grades.ratings}`
                : "10"}
              )
            </span>
          </div>
          <div>
            <span>
              {store.address} | {store.city ? store.city : "Nis"}
            </span>
          </div>
        </div>
      </div>
    </Link>
  ));

  return (
    <div className={"Stores" + (props.favourite ? " favourite" : "")}>
      {storesList}
    </div>
  );
};

export default stores;
