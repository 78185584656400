import React from "react";
import "./ReviewProgress.scss";

const ReviewProgress = ({ completed }) => {
  const fillerStyles = {
    width: `${completed}%`,
  };

  return (
    <div className="ReviewProgress">
      <div className="reviewFilled" style={fillerStyles}></div>
    </div>
  );
};

export default ReviewProgress;
