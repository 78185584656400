import React from "react";
import "./Reviews.scss";

import SingleReview from "../SingleReview/SingleReview";

const Reviews = (props) => {
  const reviewsList =
    props.reviews &&
    props.reviews.map((review, index) => (
      <SingleReview review={review} key={index} />
    ));

  return (
    <div className="Reviews">
      {reviewsList}
      {props.reviews && props.reviews.length > 0 && (
        <button className="show">Mostrar más valoraciones...</button>
      )}
    </div>
  );
};

export default Reviews;
