import * as actionTypes from './actionTypes'
import axios from '../../utility/axios/axios'

import { startLoading, finishLoading } from './loader'

export const getCategories = (categories) => {
    return {
        type: actionTypes.FETCH_CATEGORIES,
        categories:categories
    }
}

export const fetchCategories = () => {
    return (dispatch) => {
      dispatch(startLoading())
      axios
        .get('/api/category')
        .then((response) => {
          dispatch(getCategories(response.data.data))
          dispatch(finishLoading())
        })
        .catch((error) => {
          dispatch(finishLoading())
        })
    }
  }