import React, { useEffect, useState } from "react";
import "./OpeningHours.scss";

import { AiOutlineClockCircle } from "react-icons/ai";
import { MdKeyboardArrowDown } from "react-icons/md";
import dayjs from "dayjs";
import { locale } from "../../../translate/spain";

dayjs.locale("sv");

const OpeningHours = ({ hours }) => {
  const [show, setShow] = useState(false);

  const currentDay = dayjs().day();
  const currentTime = dayjs().hour();

  const workingTime = [
    hours
      ? !hours.sunday.from || !hours.sunday.to
        ? locale.openingHours.closed
        : hours.sunday.from + "-" + hours.sunday.to
      : locale.openingHours.closed,
    hours
      ? !hours.monday.from || !hours.monday.to
        ? locale.openingHours.closed
        : hours.monday.from + "-" + hours.monday.to
      : locale.openingHours.closed,
    hours
      ? !hours.tuesday.from || !hours.tuesday.to
        ? locale.openingHours.closed
        : hours.tuesday.from + "-" + hours.tuesday.to
      : locale.openingHours.closed,
    hours
      ? !hours.wednesday.from || !hours.wednesday.to
        ? locale.openingHours.closed
        : hours.wednesday.from + "-" + hours.wednesday.to
      : locale.openingHours.closed,
    hours
      ? !hours.thursday.from || !hours.thursday.to
        ? locale.openingHours.closed
        : hours.thursday.from + "-" + hours.thursday.to
      : locale.openingHours.closed,
    hours
      ? !hours.friday.from || !hours.friday.to
        ? locale.openingHours.closed
        : hours.friday.from + "-" + hours.friday.to
      : locale.openingHours.closed,
    hours
      ? !hours.saturday.from || !hours.saturday.to
        ? locale.openingHours.closed
        : hours.saturday.from + "-" + hours.saturday.to
      : locale.openingHours.closed,
  ];

  const days = [
    <div className={"time" + (currentDay === 1 ? " highlight" : "")}>
      <p>{locale.daysInWeek.monday}</p>
      <p>{workingTime[1]}</p>
    </div>,
    <div className={"time" + (currentDay === 2 ? " highlight" : "")}>
      <p>{locale.daysInWeek.tuesday}</p>
      <p>{workingTime[2]}</p>
    </div>,
    <div className={"time" + (currentDay === 3 ? " highlight" : "")}>
      <p>{locale.daysInWeek.wednesday}</p>
      <p>{workingTime[3]}</p>
    </div>,
    <div className={"time" + (currentDay === 4 ? " highlight" : "")}>
      <p>{locale.daysInWeek.thursday}</p>
      <p>{workingTime[4]}</p>
    </div>,
    <div className={"time" + (currentDay === 5 ? " highlight" : "")}>
      <p>{locale.daysInWeek.friday}</p>
      <p>{workingTime[5]}</p>
    </div>,
    <div className={"time" + (currentDay === 6 ? " highlight" : "")}>
      <p>{locale.daysInWeek.saturday}</p>
      <p>{workingTime[6]}</p>
    </div>,
    <div className={"time" + (currentDay === 0 ? " highlight" : "")}>
      <p>{locale.daysInWeek.sunday}</p>
      <p>{workingTime[0]}</p>
    </div>,
  ];

  const [isOpen, setIsOpen] = useState("");
  const [time, setTime] = useState("");

  useEffect(() => {
    // if (currentDay === 0) {
    //   setIsOpen("Cerrado ahora");
    // } else if (currentDay > 0 && currentDay < 6) {
    //   if (currentTime < 18 && currentTime > 9) {
    //     setIsOpen("Abierto ahora");
    //     setTime(workingTime[0]);
    //   } else {
    //     setIsOpen("Cerrado ahora");
    //     setTime("");
    //   }
    // } else {
    //   if (currentTime < 14 && currentTime > 9) {
    //     setIsOpen("Abierto ahora");
    //     setTime(workingTime[1]);
    //   } else {
    //     setIsOpen("Cerrado ahora");
    //     setTime("");
    //   }
    // }
    if (hours) {
      switch (currentDay) {
        case 1:
          if (!hours.monday.from || !hours.monday.to) {
            setIsOpen(locale.openingHours.closedNow);
            setTime("");
          } else {
            const parsedFrom = parseInt(hours.monday.from.substring(0, 2));
            const parsedTo = parseInt(hours.monday.to.substring(0, 2));
            if (currentTime < parsedFrom || currentTime > parsedTo) {
              setIsOpen(locale.openingHours.closedNow);
              setTime("");
            } else {
              setIsOpen(locale.openingHours.openNow);
              setTime(workingTime[1]);
            }
          }
          break;
        case 2:
          if (!hours.tuesday.from || !hours.tuesday.to) {
            setIsOpen(locale.openingHours.closedNow);
            setTime("");
          } else {
            const parsedFrom = parseInt(hours.tuesday.from.substring(0, 2));
            const parsedTo = parseInt(hours.tuesday.to.substring(0, 2));
            if (currentTime < parsedFrom || currentTime > parsedTo) {
              setIsOpen(locale.openingHours.closedNow);
              setTime("");
            } else {
              setIsOpen(locale.openingHours.openNow);
              setTime(workingTime[2]);
            }
          }
          break;
        case 3:
          if (!hours.wednesday.from || !hours.wednesday.to) {
            setIsOpen(locale.openingHours.closedNow);
            setTime("");
          } else {
            const parsedFrom = parseInt(hours.wednesday.from.substring(0, 2));
            const parsedTo = parseInt(hours.wednesday.to.substring(0, 2));
            if (currentTime < parsedFrom || currentTime > parsedTo) {
              setIsOpen(locale.openingHours.closedNow);
              setTime("");
            } else {
              setIsOpen(locale.openingHours.openNow);
              setTime(workingTime[3]);
            }
          }
          break;
        case 4:
          if (!hours.thursday.from || !hours.thursday.to) {
            setIsOpen(locale.openingHours.closedNow);
            setTime("");
          } else {
            const parsedFrom = parseInt(hours.thursday.from.substring(0, 2));
            const parsedTo = parseInt(hours.thursday.to.substring(0, 2));
            if (currentTime < parsedFrom || currentTime > parsedTo) {
              setIsOpen(locale.openingHours.closedNow);
              setTime("");
            } else {
              setIsOpen(locale.openingHours.openNow);
              setTime(workingTime[4]);
            }
          }
          break;
        case 5:
          if (!hours.friday.from || !hours.friday.to) {
            setIsOpen(locale.openingHours.closedNow);
            setTime("");
          } else {
            const parsedFrom = parseInt(hours.friday.from.substring(0, 2));
            const parsedTo = parseInt(hours.friday.to.substring(0, 2));
            if (currentTime < parsedFrom || currentTime > parsedTo) {
              setIsOpen(locale.openingHours.closedNow);
              setTime("");
            } else {
              setIsOpen(locale.openingHours.openNow);
              setTime(workingTime[5]);
            }
          }
          break;
        case 6:
          if (!hours.saturday.from || !hours.saturday.to) {
            setIsOpen(locale.openingHours.closedNow);
            setTime("");
          } else {
            const parsedFrom = parseInt(hours.saturday.from.substring(0, 2));
            const parsedTo = parseInt(hours.saturday.to.substring(0, 2));
            if (currentTime < parsedFrom || currentTime > parsedTo) {
              setIsOpen(locale.openingHours.closedNow);
              setTime("");
            } else {
              setIsOpen(locale.openingHours.openNow);
              setTime(workingTime[6]);
            }
          }
          break;
        case 0:
          if (!hours.sunday.from || !hours.sunday.to) {
            setIsOpen(locale.openingHours.closedNow);
            setTime("");
          } else {
            const parsedFrom = parseInt(hours.sunday.from.substring(0, 2));
            const parsedTo = parseInt(hours.sunday.to.substring(0, 2));
            if (currentTime < parsedFrom || currentTime > parsedTo) {
              setIsOpen(locale.openingHours.closedNow);
              setTime("");
            } else {
              setIsOpen(locale.openingHours.openNow);
              setTime(workingTime[0]);
            }
          }
          break;
        default:
          break;
      }
    }
    //eslint-disable-next-line
  }, [hours, days]);

  return (
    <div className="OpeningHours">
      <div className="header" onClick={() => setShow((p) => !p)}>
        <div className="hoursHeaderCol">
          <AiOutlineClockCircle />

          <p>{isOpen}</p>
        </div>
        <div className="hoursHeaderCol">
          <p>{time}</p>
          <MdKeyboardArrowDown
            className={"arrow" + (show ? " show" : "")}
            color={show ? "#33475b" : "#1A73E8"}
          />
        </div>
      </div>
      <div className={"times" + (show ? " show" : "")}>{days}</div>
    </div>
  );
};

export default OpeningHours;
