import React from "react";
import "./CancelCode.scss";

import Input from "../../Signup/SignupInput/SignupInput";
import { locale } from "../../../translate/spain";

const cancelCode = (props) => {
  return (
    <div className="CancelCode">
      <h3>{locale.cancelBooking.weUnderstand}</h3>
      <p>{locale.cancelBooking.cancelDecription}</p>
      <Input label={locale.cancelBooking.cancellationCode} />
      <button>{locale.cancelBooking.heading}</button>
    </div>
  );
};

export default cancelCode;
