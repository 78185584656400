import React, { useEffect } from "react";
import "./Favourite.scss";
import { useDispatch, useSelector } from "react-redux";

import PageLayout from "../../../hoc/PageLayout/PageLayout";
import StickyHeader from "../../../components/Utils/StickyHeader/StickyHeader";
import Stores from "../../../components/Universal/Stores/Stores";

import { getFavorites } from "../../../store/actions/favorites";
import { locale } from "../../../translate/spain";

const Favourite = (props) => {
  const dispatch = useDispatch();

  const { favorites } = useSelector((state) => state.favorites);

  useEffect(() => {
    dispatch(getFavorites());

    //eslint-disable-next-line
  }, []);

  return (
    <PageLayout>
      <div className="Favourite">
        <StickyHeader title={locale.navbar.myFavorites} />

        <div className="content">
          <Stores search stores={favorites} type="" city="" />
        </div>
      </div>
    </PageLayout>
  );
};

export default Favourite;
