export const validateForm = (userOrder) => {
  const newErrors = {};
  var numberPattern = new RegExp("^(?=.*?[1-9])[0-9()+-]+$");
  let stringPattern = new RegExp(/^[a-z]+$/i);
  let emailPattern = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);

  Object.entries(userOrder).forEach(([key, value]) => {
    if (
      key === "first_name" ||
      key === "last_name" ||
      key === "phone" ||
      key === "email" ||
      key === "password"
    ) {
      if (value || value !== "") {
        if (key === "phone") {
          if (!numberPattern.test(value))
            newErrors[key] = "Unesite samo brojeve";
          else if (value.length > 14)
            newErrors[key] = "Broj telefona je predugacak";
          else if (value.length < 8) newErrors[key] = "Broj telefona je kratak";
        } else if (key === "first_name") {
          if (!stringPattern.test(value))
            newErrors[key] = "Ime ne sme sadrzati brojeve";
        } else if (key === "last_name") {
          if (!stringPattern.test(value))
            newErrors[key] = "Prezime ne sme sadrzati brojeve";
        } else if (key === "email") {
          if (!emailPattern.test(value))
            newErrors[key] = "Email adresa nije ispravna";
        }
      } else {
        if (key === "phone") {
          if (value.length === 0)
            newErrors[key] = "Morate upisati broj telefona";
        } else if (key === "first_name") {
          newErrors[key] = "Morate upisati ime";
        } else if (key === "last_name") {
          newErrors[key] = "Morate upisati prezime";
        } else if (key === "email") {
          newErrors[key] = "Morate upisati Email";
        } else if (key === "password") {
          newErrors[key] = "Morate upisati password";
        }
      }
    }
  });

  return newErrors;
};
