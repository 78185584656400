import * as actionTypes from "./actionTypes";
import axios from "../../utility/axios/axios";

import { startLoading, finishLoading } from "./loader";

export const setSearchedPlaces = (payload) => {
  return {
    type: actionTypes.SET_SEARCHED_PLACES,
    payload,
  };
};

export const getSearchedPlaces = (
  categoryId,
  latitude,
  longitude,
  term,
  location,
  filter
) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .get(
        `api/search?category=${categoryId}&latitude=${latitude}&longitude=${longitude}&term=${term}&location=${location}&filter=${filter}`
      )
      .then(({ data }) => {
        dispatch(setSearchedPlaces(data.data));
        dispatch(finishLoading());
      })
      .catch((error) => {
        console.log(error);
        dispatch(finishLoading());
      });
  };
};

export const setSearchAutocomplete = (serviceTypes, places) => {
  return {
    type: actionTypes.SET_SEARCH_AUTOCOMPLETE,
    serviceTypes,
    places,
  };
};

export const searchAutocomplete = (term) => {
  return (dispatch) => {
    // dispatch(startLoading());
    axios
      .get(`api/search/autocomplete?term=${term}`)
      .then(({ data }) => {
        dispatch(
          setSearchAutocomplete(data.data.serviceTypes, data.data.places)
        );
        // dispatch(finishLoading());
      })
      .catch((error) => {
        console.log(error);
        // dispatch(finishLoading());
      });
  };
};

export const setMarkers = (payload) => {
  return {
    type: actionTypes.SET_MARKERS,
    payload,
  };
};

export const getPlacesByBounds = (ne_lat, ne_lng, sw_lat, sw_lng) => {
  return (dispatch) => {
    // dispatch(startLoading());
    axios
      .get(
        `api/places-by-location?ne_lat=${ne_lat}&ne_lng=${ne_lng}&sw_lat=${sw_lat}&sw_lng=${sw_lng}`
      )
      .then(({ data }) => {
        dispatch(setMarkers(data.data));
        dispatch(setSearchedPlaces(data.data));
        // dispatch(finishLoading());
      })
      .catch((error) => {
        // console.log(error);
        // dispatch(finishLoading());
      });
  };
};
