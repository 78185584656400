import React from "react";
import "./PlacePicture.scss";
import SwiperCore, {
  EffectCoverflow,
  Pagination,
  Autoplay,
  Navigation,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import storeShopIcon from "../../../assets/LandingPage/storeshop-icon.png";
import { ReactComponent as FavoriteIcon } from "../../../assets/Book/favorite.svg";
// import popularPlace from "../../../assets/LandingPage/popularPlace.jpg";
// import popularPlace2 from "../../../assets/LandingPage/popularPlace2.jpg";
// import { FaHeart, FaRegHeart } from "react-icons/fa";

SwiperCore.use([EffectCoverflow, Pagination, Autoplay, Navigation]);

const PlacePicture = ({ place, handleFavoritePress, isFavorite }) => {
  return (
    <div className="PlacePic">
      {place &&
        (place?.images.length > 0 ? (
          <Swiper
            navigation
            pagination={{ clickable: true }}
            slidesPerView={1}
            allowSlideNext
            allowSlidePrev
            className="Swiper"
            centeredSlides
            loop="true"
            observer
            observeParents>
            {place.images.map((item) => (
              <SwiperSlide>
                <div className="PlacePicture">
                  <img src={item.url} alt="Hair Area" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : place?.logo ? (
          <div className="PlacePicture">
            <img src={place.logo} alt="Hair Area" />
          </div>
        ) : (
          <div className="PlacePicture">
            <img src={storeShopIcon} alt="Hair Area" />
          </div>
        ))}

      <button className="favorite" onClick={handleFavoritePress}>
        {place && (
          <FavoriteIcon
            className={
              isFavorite ? "favorited" : place.is_favorite ? "fav" : ""
            }
          />
        )}
      </button>
    </div>
  );
};

export default PlacePicture;
