import React, { useState, useEffect } from "react";
import "./Place.scss";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import PageLayout from "../../../hoc/PageLayout/PageLayout";
import PlaceNavigation from "../../../components/Place/PlaceNavigation/PlaceNavigation";
import AboutUsAndDetails from "../../../components/Place/AboutUsAndDetails/AboutUsAndDetails";
import PlacePicture from "../../../components/Place/PlacePicture/PlacePicture";
import PlaceName from "../../../components/Place/PlaceName/PlaceName";
import Services from "../../../components/Signup/ServicesDisplay/ServicesDisplay";
import PlacePersonell from "../../../components/Place/PlacePersonell/PlacePersonell";
import ReviewAverage from "../../../components/Place/ReviewAverage/ReviewAverage";
import Reviews from "../../../components/Place/Reviews/Reviews";
import PlaceNameMobile from "../../../components/Place/PlaceNameMobile/PlaceNameMobile";

import { Element } from "react-scroll";
import { BiArrowBack } from "react-icons/bi";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { getPlace } from "../../../store/actions/place";
import {
  addToFavorites,
  removeFromFavorites,
} from "../../../store/actions/favorites";
import { locale } from "../../../translate/spain";

const Place = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [term, setTerm] = useState("");
  const [location, setLocation] = useState("");
  const [isFavorite, setIsFavorite] = useState(false);
  const { place } = useSelector((state) => state.place);
  const authorized = useSelector((state) => state.account.authorized);

  useEffect(() => {
    dispatch(getPlace(id));
    const query = new URLSearchParams(props.location.search);
    setTerm(query.get("term"));
    setLocation(query.get("location"));
    //eslint-disable-next-line
  }, [id]);

  const handleFavoritePress = () => {
    if (place) {
      if (authorized) {
        if (place.is_favorite) {
          dispatch(removeFromFavorites(place.id));
          setIsFavorite(false);
        } else {
          dispatch(addToFavorites(place.id));
          setIsFavorite(true);
        }
      } else {
        props.history.push(`/login/${place.id}`);
      }
    }
  };

  const [firstInputClick, setFirstInputClick] = useState(false);
  const [secondInputClick, setSecondInputClick] = useState(false);

  const handleFirstInputClick = (val) => {
    setFirstInputClick(val);
  };

  const handleSecondInputClick = (val) => {
    setSecondInputClick(val);
  };

  useEffect(() => {
    const listener = () => {
      const windowY = window.scrollY;
      if (windowY > 30) {
        document.querySelector(".Navigation").className =
          "Navigation place bottom-shadow";
      } else {
        document.querySelector(".Navigation").className = "Navigation place";
      }
      /* 300 + 80 + 24 */
      if (windowY > 415) {
        document.querySelector(".mobile-navigation").className =
          "mobile-navigation scrolled";
      } else {
        document.querySelector(".mobile-navigation").className =
          "mobile-navigation not-scrolled";
      }
    };
    window.addEventListener("scroll", listener);

    return () => window.removeEventListener("scroll", listener);
  }, []);

  return (
    <PageLayout
      sticky
      searchForm
      term={term}
      location={location}
      handleFirstInputClick={handleFirstInputClick}
      handleSecondInputClick={handleSecondInputClick}
      firstInputFocus={firstInputClick}
      secondInputFocus={secondInputClick}
      place
      footer>
      <div
        className='Place'
        onClick={() => {
          if (firstInputClick || secondInputClick) {
            setFirstInputClick(false);
            setSecondInputClick(false);
          }
        }}>
        <div className='mobile-navigation not-scrolled'>
          <div className='flex'>
            <div className='left'>
              <div className='go-back' onClick={() => props.history.goBack()}>
                <BiArrowBack />
              </div>
              <h3>{place && place.name}</h3>
            </div>
            <div className='favorite' onClick={handleFavoritePress}>
              {place && place.is_favorite ? (
                <FaHeart className='fav' />
              ) : (
                <FaRegHeart />
              )}
            </div>
          </div>
          <PlaceNavigation {...props} name={place ? place.name : ""} />
        </div>
        <div className='main'>
          <div className='go-back' onClick={() => props.history.goBack()}>
            <BiArrowBack />
          </div>
          <div className='place-details'>
            <div className='container'>
              <Element name='overview'>
                <PlacePicture
                  place={place}
                  handleFavoritePress={handleFavoritePress}
                  isFavorite={isFavorite}
                />
                <PlaceName place={place} />
                <PlaceNameMobile
                  place={place}
                  handleFavoritePress={handleFavoritePress}
                />
              </Element>

              <div className='navigation-favourite'>
                <PlaceNavigation {...props} name={place ? place.name : ""} />
              </div>

              <Element name='services'>
                <h3>{locale.place.servicesHeading}</h3>
                <Services
                  boka
                  services={place && place.services ? place.services : []}
                  place={place}
                />
              </Element>
              <Element name='personell'>
                <h3>{locale.place.personellHeading}</h3>
                <PlacePersonell
                  persons={place && place.staff ? place.staff : []}
                  place_id={place && place.id}
                />
              </Element>
              <Element name='reviews'>
                <h3>{locale.place.reviewsHeading}</h3>
                <ReviewAverage place={place} />
                <Reviews reviews={place && place.reviews} />
              </Element>
            </div>
          </div>
          {place && <AboutUsAndDetails place={place} term={term} />}
        </div>
      </div>
    </PageLayout>
  );
};

export default Place;
