import React, { useEffect, useState } from "react";
import "./LandingPage.scss";

import { connect, useSelector } from "react-redux";
import { fetchCategories } from "../../../store/actions/categories";
import { fetchRecomendedPlaces } from "../../../store/actions/place";

import Discover from "../../../components/LandingPage/Discover/Discover";
import Areas from "../../../components/LandingPage/Areas/Areas";

import PageLayout from "../../../hoc/PageLayout/PageLayout";
import SearchService from "../../LandingPageSearch/SearchService/SearchService";
import PopularPlaces from "../../../components/LandingPage/PopularPlaces/PopularPlaces";

import { locale } from "../../../translate/spain";

const LandingPage = (props) => {
  const [mobileSearchStep, setMobileSearchStep] = useState(0);
  // const [headerChange, setHeaderChange] = useState(true);
  const categories = useSelector((state) => state.categories);
  const recomendedPlaces = useSelector((state) => state.place.recomendedPlaces);

  useEffect(() => {
    if (categories.length === 0) {
      props.fetchCategories();
      props.fetchRecomendedPlaces();
    }
  }, [categories, props]);

  // useEffect(() => {
  //   const onScroll = (e) => {
  //     if (headerChange && window.scrollY > 20) {
  //       setHeaderChange(false);
  //     } else if (!headerChange && window.scrollY < 20) {
  //       setHeaderChange(true);
  //     }
  //   };

  //   window.addEventListener("scroll", onScroll);

  //   return () => window.removeEventListener("scroll", onScroll);
  // }, [headerChange]);

  const [firstInputClick, setFirstInputClick] = useState(false);
  const [secondInputClick, setSecondInputClick] = useState(false);

  const handleFirstInputClick = (val) => {
    setFirstInputClick(val);
  };

  const handleSecondInputClick = (val) => {
    setSecondInputClick(val);
  };

  return (
    <PageLayout extended footer landingPage>
      <div
        className='LandingPage'
        onClick={() => {
          if (firstInputClick || secondInputClick) {
            setFirstInputClick(false);
            setSecondInputClick(false);
          }
        }}>
        <Discover
          setStep={setMobileSearchStep}
          handleFirstInputClick={handleFirstInputClick}
          handleSecondInputClick={handleSecondInputClick}
          firstInputFocus={firstInputClick}
          secondInputFocus={secondInputClick}
        />
        <div className='container'>
          <h2>{locale.landingPage.topCategories}</h2>
          <Areas areas={categories} />
          <h2>{locale.landingPage.popularPlaces}</h2>
          <PopularPlaces recomendedPlaces={recomendedPlaces} />
        </div>
      </div>

      {mobileSearchStep === 1 && (
        <SearchService setStep={setMobileSearchStep} />
      )}
    </PageLayout>
  );
};

export default connect(null, { fetchCategories, fetchRecomendedPlaces })(
  LandingPage
);
