import React from "react";
import "./SignupInput.scss";

const signupInput = (props) => {
  let input = (
    <input
      name={props.name}
      value={props.value}
      onChange={(e) => props.onChange(e.target.value)}
      placeholder={props.placeholder && props.placeholder}
      type={props.type ? props.type : "text"}
      disabled={props.disable}
      required
      className={props.error ? "error" : ""}
    />
  );
  let label = <label>{props.label}</label>;
  let description = null;
  let link = null;

  if (props.showPassword) {
    label = (
      <div className="createAndShow">
        <label>Create password</label>
        <p
          className={props.type !== "password" ? "active" : ""}
          onClick={props.showPassword}>
          Show passowrd
        </p>
      </div>
    );
  }

  if (props.textarea) {
    input = (
      <textarea
        placeholder={props.placeholder && props.placeholder}
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}></textarea>
    );
  }

  if (props.description) {
    description = <p>{props.description}</p>;
  }

  if (props.button) {
    link = <button onClick={props.button.action}>{props.button.text}</button>;
  }

  return (
    <div className={"SignupInput" + (props.svg ? " svg" : "")}>
      {label}
      {description}
      {link}
      {input}
      {props.error && <small className="form-error">{props.error}</small>}
      {props.children}
    </div>
  );
};

export default signupInput;
