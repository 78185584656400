import React from "react";
import "./PlacePersonell.scss";
import { Link } from "react-router-dom";

// import Stars from "react-stars-display";

// import { FaRegUser } from "react-icons/fa";
// import { MdKeyboardArrowRight } from "react-icons/md";

const personell = (props) => {
  const clicked = () => {
    if (props.select) {
      props.select();
    }
  };

  const persons = props.persons.map((person, index) => (
    <Link to={`/book/${props.place_id}?worker=${person.id}`} key={index}>
      <div
        className={"person" + (props.select ? " selectable" : "")}
        onClick={clicked}
      >
        <div className="img">
          {/* <FaRegUser /> */}
          <img src={person.avatar_fake} alt="Peronell avatar" />
          {person.average_rating && (
            <div className="rating">
              <span>★</span>
              <p>{person.average_rating}</p>
            </div>
          )}
        </div>
        <div className="name">
          <p>{person.first_name}</p>
          <span>{person.last_name}</span>
        </div>
      </div>
    </Link>
  ));

  return <div className="PlacePersonell">{persons}</div>;
};

export default personell;
