import React, { useState, useEffect, useRef } from "react";
import "./BookCalendar.scss";
import { useSelector } from "react-redux";

import { HiArrowLeft, HiArrowRight } from "react-icons/hi";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import dayjs from "dayjs";
import { locale } from "../../../translate/spain";

const BookCalendar = (props) => {
  const [position, setPosition] = useState(0);
  const [currMonth, setCurrMonth] = useState("");
  const weekRef = useRef(null);
  const [mobileYprevious, setMobileYPrevious] = useState(null);

  const daysNames = [
    locale.days3letters.sunday,
    locale.days3letters.monday,
    locale.days3letters.tuesday,
    locale.days3letters.wednesday,
    locale.days3letters.thursday,
    locale.days3letters.friday,
    locale.days3letters.saturday,
  ];

  const freeSlots = useSelector((state) => state.booking.freeSlots);

  useEffect(() => {
    const scrollOverride = (e) => {
      let y = 0;
      const slots = document.querySelectorAll(".slots");
      if (e.type === "touchmove") {
        y = e.touches[0].pageY;
        setMobileYPrevious(y);
        if (mobileYprevious) {
          y = mobileYprevious - y;
        } else y = 0;

        if (y < 50 || y > 50) {
        } else y = 0;

        if (y) {
          slots.forEach((slot) => {
            let scrolled = slot.scrollTop;
            slot.scroll(0, scrolled + y);
          });
        }
      } else {
        y = e.deltaY;

        slots.forEach((slot) => {
          let scrolled = slot.scrollTop;
          slot.scroll(0, scrolled + y / 5);
        });
      }
      e.preventDefault();
    };

    const touchEndCalled = (e) => {
      setMobileYPrevious(null);
    };

    if (weekRef.current) {
      weekRef.current.addEventListener("wheel", scrollOverride, {
        passive: false,
      });

      weekRef.current.addEventListener("touchmove", scrollOverride);
      weekRef.current.addEventListener("touchend", touchEndCalled);
    }
  }, [mobileYprevious]);

  const dayInWeek = (date, values, index) => {
    const dayName = daysNames[dayjs(date).day()];

    return (
      <div className="Day" key={index}>
        <div className="title">
          <span>{dayName}</span>
          <p>{dayjs(date).format("DD")}</p>
        </div>
        <div className="slots">
          {values &&
            values.map((time, index) => (
              <button
                key={index}
                onClick={() => props.select(date, time.from)}
                className="available"
              >
                {time.from.substring(0, 5)}
              </button>
            ))}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (freeSlots) {
      const start = position * 5;
      // const len = Object.keys(freeSlots).length;
      // const end = position * 5 + 5 > len - 1 ? len - 1 : position * 5 + 5;
      // setStartDate(dayjs(Object.keys(freeSlots)[start]).format("DD"));
      // setEndDate(dayjs(Object.keys(freeSlots)[end]).format("DD"));
      setCurrMonth(
        `${dayjs(Object.keys(freeSlots)[start]).format("MMMM")} ${dayjs(
          Object.keys(freeSlots)[start]
        ).format("YYYY")}`
      );
    }
    // eslint-disable-next-line
  }, [position, currMonth, freeSlots]);

  return (
    <div className="BookCalendar">
      <div className="header">
        <div
          className="arrow"
          onClick={() => position !== 0 && setPosition((p) => p - 1)}
        >
          <BsChevronLeft />
          {locale.book.earlier}
        </div>
        <h2>{`${currMonth}`}</h2>
        <div
          className="arrow later"
          onClick={() =>
            position <= Object.keys(freeSlots).length / 5 - 1 &&
            setPosition((p) => p + 1)
          }
        >
          {locale.book.later} <BsChevronRight />
        </div>
      </div>

      <div className="week" ref={weekRef}>
        {freeSlots &&
          Object.keys(freeSlots)
            .slice(position > 0 ? position * 5 : position * 5, position * 5 + 5)
            .map((day, index) => {
              return dayInWeek(day, freeSlots[day], index);
            })}
      </div>
      <div
        className="arrow-mobile left"
        onClick={() => {
          position !== 0 && setPosition((p) => p - 1);
          setMobileYPrevious(0);
        }}
      >
        <HiArrowLeft />
      </div>
      <div
        className="arrow-mobile right"
        onClick={() => {
          position <= Object.keys(freeSlots).length / 5 - 1 &&
            setPosition((p) => p + 1);
          setMobileYPrevious(0);
        }}
      >
        <HiArrowRight />
      </div>
    </div>
  );
};

export default BookCalendar;
