import React from "react";
import "./BookingCategories.scss";

import { RiBookletLine } from "react-icons/ri";
import { locale } from "../../../translate/spain";

const bookingCategories = (props) => {
  const services = props.service && (
    // props.services.map((service) => (
    <div className="Service">
      <div className="description">
        <RiBookletLine />
        <div className="name">
          <h4 className="service_name">{props.service.service}</h4>
          <p>
            {props.service.duration} {locale.minutes}{" "}
            {props.worker ? `${locale.with} ${props.worker.first_name}` : ""}
          </p>
        </div>
      </div>
    </div>
  );
  // ));

  // const workers = props.worker && (
  //   // props.workers.map((worker) => (
  //   <div className="Worker">
  //     <div className="description">
  //       <RiBookletLine />
  //       <div className="name">
  //         <p>{props.worker.first_name}</p>
  //         <span>{props.worker.last_name}</span>
  //       </div>
  //     </div>

  //     <div className="image">
  //       {props.worker.avatar_fake ? (
  //         <img src={props.worker.avatar_fake} alt="Avatar" />
  //       ) : (
  //         <FaRegUser />
  //       )}
  //     </div>
  //   </div>
  // );
  // ));

  return (
    <div className="BookingCategories">
      {props.service && services}
      {/* {props.worker && workers} */}
      <hr />
    </div>
  );
};

export default bookingCategories;
