import React from "react";
import "../Login/Login.scss";

import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { getProfile } from "../../../store/actions/account";

import Bookybook from "../../../assets/layout/Bookybook.png";
import SignupForm from "../../../components/Signup/SignupForm/SignupForm";
import { BiArrowBack } from "react-icons/bi";
import { locale } from "../../../translate/spain";

const Login = (props) => {
  const { history } = props;

  return (
    <div className='Login signup'>
      <div className='topArea'>
        <div className='back' onClick={() => history.goBack()}>
          <BiArrowBack />
        </div>
        <Link to='/'>
          <img className='logo' src={Bookybook} alt='Bookybook' />
        </Link>
      </div>
      <div className='loginContent'>
        <h1>{locale.auth.signupHeading}</h1>
        <SignupForm history={history} />
      </div>
      <div className='loginContent professionals'>
        <h1>{locale.auth.becomeAPartner}</h1>
        <p>
          {locale.auth.manageYourBusiness}{" "}
          <a href={process.env.REACT_APP_B2B_LINK_SIGNUP}>
            {locale.auth.signupAsPro}
          </a>
        </p>
      </div>
      <span className='allRights'>© {locale.footer.copyRight}</span>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.account.profile,
  };
};
export default connect(mapStateToProps, { getProfile })(Login);
