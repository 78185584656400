import React from "react";
import "./AlreadyHaveAccount.scss";

import { Link } from "react-router-dom";

const alreadyHaveAccount = (props) => {
  return (
    <div className="AlreadyHaveAccount">
      <p>
        Already have an account?{" "}
        {props.book ? (
          <button onClick={props.book} className="login">
            Log in
          </button>
        ) : (
          <Link to="login/auth">Log in</Link>
        )}
      </p>
    </div>
  );
};

export default alreadyHaveAccount;
