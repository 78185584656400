import React, { useState } from "react";
import "./LoginForm.scss";

import { withRouter, useParams } from "react-router-dom";

import { connect } from "react-redux";
import { login } from "../../../store/actions/account";

import { Link } from "react-router-dom";

import Input from "../../../components/Signup/SignupInput/SignupInput";
import { locale } from "../../../translate/spain";

const LoginForm = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { type } = useParams();

  const setEmailHandler = (property) => {
    setEmail(property);
  };

  const setPasswordHandler = (property) => {
    setPassword(property);
  };

  const logIn = (e) => {
    e.preventDefault();
    props.login(email, password, type, props.back, props.history);
  };

  return (
    <form onSubmit={logIn} className='LoginForm'>
      <Input
        label={locale.auth.emailLabel}
        value={email}
        onChange={setEmailHandler}
        placeholder={locale.auth.emailPlaceholder}
      />
      <Input
        label={locale.auth.passwordLabel}
        type='password'
        value={password}
        onChange={setPasswordHandler}
        placeholder={locale.auth.passwordPlaceholder}
      />

      <div className='keepForgot'>
        <Link to='/forgot-password'>{locale.auth.forgotPassword}</Link>
      </div>

      <button type='submit'>{locale.auth.loginBtn}</button>
    </form>
  );
};

export default connect(null, { login })(withRouter(LoginForm));
