import React, { useState } from "react";
import "./EditEmail.scss";

import { connect } from "react-redux";

import { changeEmail } from "../../../store/actions/account";

import Input from "../../../components/Signup/SignupInput/SignupInput";
import { MdClose } from "react-icons/md";

const EditEmail = (props) => {
  const [email, setEmail] = useState("");

  const setEmailHandler = (property) => {
    setEmail(property);
  };

  const changeEmail = () => {
    props.changeEmail(email, props.close);
  };

  return (
    <div className="EditEmail">
      <div className="overlay" onClick={props.close} />
      <div className="slider">
        <div className="header">
          <p>{props.translate.title}</p>
          <MdClose onClick={props.close} />
        </div>

        <div className="container">
          <p>{props.translate.description}</p>
          <Input
            label={props.translate.label}
            placeholder={props.translate.newPlaceholder}
            value={email}
            onChange={setEmailHandler}
          />

          <div className="buttons">
            <button onClick={changeEmail}>{props.translate.confirmButton}</button>
            <button onClick={props.close}>{props.translate.declineButton}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { changeEmail })(EditEmail);
