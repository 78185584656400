export const locale = {
  landingPage: {
    discover: {
      title: "Otkrijete salone i rezervišite svoje termine",
      searchServiceLabel: "Pretraga?",
      searchServicePlaceholder: "Pronadjite tretmane ili salone",
      searchCityLabel: "Gde?",
      searchCityPlaceholder: "Unesite svoj grad ili oblast",
      searchBtn: "Traži",
      goToB2bBoldText: "Da li imate privatan biznis? ",
      goToB2bClassic: " Registrujte svoju firmu ",
      goToB2bLink: "ovde.",
    },
    topCategories: "Ponuda usluga",
    popularPlaces: " Preporučuje se za Vas",
  },
  navbar: {
    bookings: "Moje rezervacije",
    login: "Prijavi se",
    signup: "Registruj se",
    registerBusiness: "Registrujte svoj biznis",
    myReservations: "Moje rezervacije",
    cancelBooking: "Otkažite rezervaciju",
    forProfesionals: "Registrujte svoj biznis",
    price: "Cena",
    myFavorites: "Omiljeni",
    logout: "Odjavi se",
    profile: "Podešavanja",
  },
  links: {
    contact: "Kontakt",
    about: "O nama",
    privacyPolicy: "Pravila o privatnosti",
    termsAndConditions: "Uslovi korišćenja",
  },
  footer: {
    copyRight: "2021 Bookybook, Zadržana sva prava.",
    facebookLink: "http://facebook.com/bookybook.se",
    instagramLink: "http://www.instagram.com/bookybook.se/",
    twitterLink: "#!",
  },
  searchPage: {
    nearMe: "Trenutna lokacija",
    results: "rezultat",
    for: "za",
    in: "u",
    showMap: "Prikaži mapu",
    sortBy: "Filteri",
    nearest: "Najbliži",
    bestRated: "Najbolje ocenjeni",
    editSearch: "Uredi pretragu",
    updateResultsWhenMovingMap: "Ažurirajte podatke kada pomerate mapu",
  },
  showMore: "Opširnije",
  placeAboutUs: {
    aboutUs: "Na",
    showNumber: "Prokaži broj",
    showEmail: "Prikažite email",
  },
  place: {
    favorite: "Štedi novac",
    call: "Pozvovi",
    email: "Email",
    directions: "PUTANJA",
    information: "INFORMACIJE",
    services: "USLUGE",
    personell: "OSOBLJE",
    reviews: "UTISCI",
    servicesHeading: "Rezervišite uslugu",
    personellHeading: "Rezervišite sa zaposlenima",
    reviewsHeading: "Utisci",
    verifiedReviews: "Proverena recenzija",
    verifiedReviewsDescription:
      "Sve naše recenzije su od verifikovanih klijenata.",
  },
  auth: {
    //login
    loginHeading: "Prijavite se putem email-a",
    emailLabel: "Email adresa",
    emailPlaceholder: "Vaša email adresa",
    passwordLabel: "Lozinka",
    passwordPlaceholder: "Vaša lozinka",
    forgotPassword: "Zaboravili ste lozinku?",
    loginBtn: "Prijavite se",
    dontHaveAcc: "Nemate nalog?",
    loginForProfessionals: "Prijava za biznise",
    loginProfDescription: "Da bi ste pristupili partnerskom nalogu idite na",
    bussinesLoginPage: "Stranica za biznise",
    // signup
    signupBtn: "Prijavi se",
    signupHeading: "Prijavite se da rezervišete",
    firstNameLabel: "Ime",
    firstNamePlaceholder: "Vaše ime",
    lastNameLabel: "Prezime",
    lastNamePlaceholder: "Vaše prezime",
    mobileNumberLabel: "Broj telefona",
    addressLabel: "Adresa",
    postalCode: "Poštanski broj",
    city: "Grad",
    callNumber: "+381",
    iAgree: "Slažem se sa",
    privacyPolicy: "pravilama o privatnosti",
    websiteTerms: "web stranice",
    and: "i",
    bookingTerms: "uslovima rezervacije",
    alreadyHaveAcc: "Vec imate nalog?",
    becomeAPartner: "Postanite partner",
    manageYourBusiness: "Upravljajte svojim poslovanjem uz Bookybook",
    signupAsPro: "prijavite se kao partner",
    createPassword: "Kreirajte  lozinku",
    forgotPasswordForm: {
      title: "Zaboravili ste lozinku?",
      label:
        "Unesite Vašu email adresu da bi ste dobili link za promenu lozinke",
      button: "Pošalji",
    },
    changePasswordForm: {
      title: "Promenite lozinku",
      label1: "Nova lozinka",
      label2: "Potvrdite novu lozinku",
      button: "Promenite lozinku",
    },
  },
  cancelBooking: {
    heading: "Otkašite rezervaciju",
    weUnderstand: "Mi razumemo",
    cancelDecription:
      "Unesite kod da biste otkazali rezervaciju. Kod možete pronaći u email-u sa potvrdom rezervacije.",
    cancellationCode: "Kod za otkazivanje",
  },
  appointment: {
    cancellationModal: {
      title: "Da li ste sigurni da želite da otkažete rezervaciju?",
      confirmButton: "Potvrdi",
      discardButton: "Odustani",
    },
  },
  myBookings: {
    title: "Moje rezervacije",
    upcomingBookings: "Zakazane rezervacije",
    finishedBookings: "Završene rezervacije",
    cancelledBookings: "Otkazane rezervacije",
    upcomings: "Zakazane",
    finished: "Završene",
    canceled: "Otkazane",
    totalAmount: "Ukupno",
    paymentMethod: "Način plaćanja: Plaćanje na licu mesta",
    bookAgain: "Rezervišite ponovo",
    at: "u",
    // THESE ARE ON SWEDEN
    noUpcomingBookingsTitle: "Priuštite sebi tretman",
    noUpcomingBookingsDesc:
      "Kada izvršite rezervaciju, ona će se ovde pojaviti",
    makeBookings: "Napravite rezervaciju",
    noFinishedBookingsTitle: "Završene usluge",
    noFinishedBookingsDesc:
      "Rezervacije koje su završene ili otkazane biće prikazane ovde",
  },
  book: {
    step: "Korak",
    of: "of",
    chooseStaff: "Izaberite osoblje",
    chooseTime: "Izaberite vreme",
    chooseService: "Izaberite uslugu",
    earlier: "Ranije",
    later: "Kasnije",
    reviewAndConfirm: "Pogledajte i potvrdite",
    loggedInAs: "Prijavljeni ste kao: ",
    cancellationPolicy: "Politika otkazivanja",
    cancelForFree: "Otkažite besplatno do ",
    ahead: " napred",
    bookingNotesLabel: "Dodajte beleške o rezervaciji",
    bookingNotes: "Beleške o rezervaciji",
    bookingNotesDesc: "Uključite komentare ili zahteve o vašoj rezervaciji",
    iAccept: "Prihvatam ",
    loginToContinue: "Prijavite se da bi ste nastavili",
    signupToContinue: "Registrujte se da bi ste nastavili",
    noWorkers: "Nema radnika za ovu uslugu...",
    choose: "Izaberite",
    termsAndConditions: "uslovi.",
    confirmAndBook: "Potvrdite i rezervišite",
    byClicking: 'Klikom na "Potvrdite i rezervišite" prihvatate ',
  },
  profile: {
    heading: "Podešavanja",
    photoDescription: "Ova slika će biti prikazana na stranici vašeg profila",
    uploadPicture: "Ubacite profilnu sliku",
    deletePicture: "Obrišite",
    editEmail: "Izmenite email adresu",
    save: "Sačuvajte izmene",
    changePasswordDesc: "Promenite lozinku na vašem nalogu",
    changePassBtn: "Promenite lozinku",
    deleteAccountTitle: "Obrišite nalog",
    deleteAccountDesc: "Jednom kada obrišete nalog, ne možete ga vratiti",
    deleteAccountBtn: "Obrišite nalog",
    resetPassword: {
      title: "Promenite lozinku",
      currentPassword: "Potvrdite trenutnu lozinku",
      forgotPassword: "Zaboravili ste lozinku",
      currentPlaceholder: "Unesite trenutnu lozinku",
      newPassword: "Nova lozinka",
      showPassword: "Prikažite lozinku",
      hidePassword: "Sakrijte lozinku",
      newPlaceholder: "Unesite novu lozinku",
      confirmButton: "Resetuj lozinku",
      declineButton: "Odustani",
    },
    editEmailAddress: {
      title: "Promenite vašu email adresu",
      description:
        "Moraćete da proverite svoju e-poštu i potvrdite novu adresu.",
      label: "E-Mail",
      newPlaceholder: "Unesite novu email adresu",
      confirmButton: "Sačuvaj",
      declineButton: "Odustani",
    },
    deleteAccount: {
      title: "Obrišite svoj nalog",
      description:
        "Kada izbrišete svoj nalog, nema povratka i više se ne možete prijaviti na ovaj nalog. Budite sigurni pre nego što izbrišete svoj nalog.",
      confirmButton: "Obrišite svoj nalog",
      declineButton: "Odustani",
    },
  },
  aboutUs: {
    title: "O Bookybook-u",
    description: `Pre nastanka Bookybook, bili smo šokirani koliko je vremena i truda potrebno za zakazivanja tretmana za negu lica i tela,
		 takodje bili smo šokirani i  koliko vremena pružaoci  usluga gube na administraciji. Odlučili smo da preduzmemo nešto povodom toga, a to je 
		Bookybook. Alat  za rezervaciju za pružaoce usluga prvenstveno u kategoriji lepote. On  pomaže i kupcu i kompaniji koja nudi usluge praveći proces zakazivanja što bržim i jednostavnijim i tako šteći vreme i trud.
    title2: "Vi ste otkriće`,
    description2: `Naša cilj je da pomognemo svakom da postane najbolja verzija sebe. Vi ste naša najveća inspiracija i razlog zašto unapređujemo sebe 
		konstantno. Svaki izbor koji biramo- biramo sa mislima o vama. Želimo da budemo najlakši, najbrži i naajpouzdaniji
		alat koji ćete vi  i pružaoci usluga koristiti.
		Zajedno stvaramo nešto magično  
    teamTitle: "Upoznajte tim",
    teamDesc: "Mi smo rećni zato što radimo ono što volimo.`,
  },
  with: "sa",
  featured: "Izabrano",
  reserve: "Rezervisati",
  minutes: "minuti",
  days: "dani",
  hours: "sati",
  currency: "RSD",
  save: "Sačuvaj",
  openingHours: {
    closed: "Zatvoreno",
    closedNow: "Trenutno zatvoreno",
    openNow: "Trenutno otvoreno",
  },
  daysInWeek: {
    monday: "Ponedeljak",
    tuesday: "Utorak",
    wednesday: "Sreda",
    thursday: "Četvrtak",
    friday: "Petak",
    saturday: "Subota",
    sunday: "Nedelja",
  },
  days3letters: {
    monday: "Pon",
    tuesday: "Uto",
    wednesday: "Sre",
    thursday: "Čet",
    friday: "Pet",
    saturday: "Sub",
    sunday: "Ned",
  },
  grades: {
    "1-2": "Loše",
    "2-3.5": "Zadovoljavajuće",
    "3.5-4.5": "Dobro",
    "4.5-5": "Odlično",
    unrated: "Ne ocenjeno",
    ratings: "Ocene",
  },
};
