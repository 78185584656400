import React from "react";
import "../LandingPageSearch.scss";

import { HiArrowLeft } from "react-icons/hi";
import { MdClose } from "react-icons/md";

const SearchService = (props) => {
  const submit = (e) => {
    e.preventDefault();
    props.setStep(0);
  };
  return (
    <div className="LandingPageSearch">
      <form className="search" onSubmit={submit}>
        <HiArrowLeft color="#1A73E8" onClick={() => props.setStep(0)} />
        <input
          className="text"
          placeholder="Write service, provider or company"
        />
        <button className="close" onClick={() => props.setStep(0)}>
          <MdClose />
        </button>
      </form>
    </div>
  );
};

export default SearchService;
