import React, { useState } from "react";
import "./SignupForm.scss";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { validateForm } from "../../../helpers/form-validation";
import Input from "../SignupInput/SignupInput";
import { MdCheck } from "react-icons/md";
import { signup } from "../../../store/actions/account";
import { locale } from "../../../translate/spain";

const SignupForm = ({ history }) => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [errors, setErrors] = useState({});

  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    password: "",
    password_confirmation: "",
  });

  const signupHandler = () => {
    let errorsValidation = validateForm(user);
    setErrors(errorsValidation);

    if (Object.keys(errorsValidation).length === 0) {
      if (checked) {
        dispatch(
          signup(
            user.email,
            user.first_name,
            user.last_name,
            user.phone,
            user.password,
            user.password_confirmation,
            history
          )
        );
      }
    }
  };

  return (
    <div className='SignupForm'>
      <div className='row'>
        <Input
          label={locale.auth.firstNameLabel}
          value={user.first_name}
          onChange={(val) => setUser((p) => ({ ...p, first_name: val }))}
          placeholder={locale.auth.firstNamePlaceholder}
          error={errors.first_name}
        />
        <Input
          label={locale.auth.lastNameLabel}
          value={user.last_name}
          onChange={(val) => setUser((p) => ({ ...p, last_name: val }))}
          placeholder={locale.auth.lastNamePlaceholder}
          error={errors.last_name}
        />
      </div>
      <div className='row'>
        <Input
          label={locale.auth.mobileNumberLabel}
          value={user.phone}
          type='tel'
          onChange={(val) => setUser((p) => ({ ...p, phone: val }))}
          error={errors.phone}>
          <p>{locale.auth.callNumber}</p>
        </Input>
        <Input
          label={locale.auth.emailLabel}
          value={user.email}
          onChange={(val) => setUser((p) => ({ ...p, email: val }))}
          placeholder={locale.auth.emailPlaceholder}
          error={errors.email}
        />
      </div>
      <div className='row'>
        <Input
          label={locale.auth.passwordLabel}
          type='password'
          value={user.password}
          onChange={(val) =>
            setUser((p) => ({
              ...p,
              password: val,
              password_confirmation: val,
            }))
          }
          placeholder={locale.auth.createPassword}
          error={errors.password}
        />
        {/* <Input
          label="Confirm password"
          type="password"
          value={user.password_confirmation}
          onChange={(val) =>
            setUser((p) => ({ ...p, password_confirmation: val }))
          }
          placeholder="Confirm your password"
        /> */}
      </div>
      <div className='terms'>
        <div
          className={"checkbox" + (checked ? " active" : "")}
          onClick={(e) => {
            e.stopPropagation();
            setChecked((p) => !p);
          }}>
          <MdCheck />
        </div>
        <p>
          {locale.auth.iAgree} <a href='#!'>{locale.auth.privacyPolicy}</a>,{" "}
          <a href='#!'>{locale.auth.websiteTerms}</a> and{" "}
          <a href='#!'>{locale.auth.bookingTerms}</a>
        </p>
      </div>
      <button disabled={!checked} onClick={signupHandler}>
        {locale.auth.signupBtn}
      </button>
      <div className='bottom'>
        <p>{locale.auth.alreadyHaveAcc}</p>
        <Link to='login/auth'>{locale.auth.loginBtn}</Link>
      </div>
    </div>
  );
};

export default SignupForm;
