import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { locale } from "../../../translate/spain";
import Bookybook from "../../../assets/Signup/Logo.png";
import { forgotPassword } from "../../../store/actions/account";

import "./ForgotPassword.scss";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");

  const submitForm = (e) => {
    e.preventDefault();
    dispatch(forgotPassword(email));
  };

  return (
    <div className='ForgotPassword'>
      <form onSubmit={submitForm}>
        <div className='form_header'>
          <img src={Bookybook} />
          <h1>{locale.auth.forgotPasswordForm.title}</h1>
        </div>
        <div className='form_body'>
          <label for='helper-text' className='helper-text'>
            Email
          </label>
          <label for='helper-text' className='helper-text'>
            {locale.auth.forgotPasswordForm.label}
          </label>
          <input
            type='email'
            id='helper-text'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            aria-describedby='helper-text-explanation'
            // placeholder='name@flowbite.com'
          />

          <button type='submit'>{locale.auth.forgotPasswordForm.button}</button>
        </div>
      </form>
    </div>
  );
};

export default ForgotPassword;
