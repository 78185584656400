import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "./Discover.scss";

import axios from "axios";
import { GrSearch } from "react-icons/gr";
import { MdClose } from "react-icons/md";
import Suggestion from "../../Search/Suggestion/Suggestion";
import { searchAutocomplete } from "../../../store/actions/search";
import { locale } from "../../../translate/spain";
import {
  locationAutoComplete,
  currentAutoLocation,
} from "../../../store/actions/locations";
// import Input from "../../Signup/SignupInput/SignupInput";

let searchTimeout = null;
const Discover = (props) => {
  const dispatch = useDispatch();
  const [service, setService] = useState("");
  const [area, setArea] = useState("");
  const serviceTypes = useSelector((state) => state.search.serviceTypes);
  const places = useSelector((state) => state.search.places);
  const [serviceSuggestions, setServiceSuggestions] = useState([]);
  const [areaSuggestions, setAreaSuggestions] = useState([]);
  const [locationCords, setLocationCords] = useState({});

  const history = useHistory();

  const deleteService = () => {
    setService("");
  };

  const deleteArea = () => {
    setArea("");
  };

  const firstInputClick = (e) => {
    e.stopPropagation();
    props.handleSecondInputClick(false);
    props.handleFirstInputClick(true);
  };

  const secondInputClick = (e) => {
    e.stopPropagation();
    props.handleFirstInputClick(false);
    props.handleSecondInputClick(true);
  };

  const setServiceHandler = (value) => {
    setService(value);
    if (value.length >= 2) {
      dispatch(searchAutocomplete(value));
    }
  };

  useEffect(() => {
    setServiceSuggestions(serviceTypes.slice(0, 4).concat(places.slice(0, 4)));
  }, [serviceTypes, places]);

  const locationSuggestion = (property) => {
    clearTimeout(searchTimeout);

    setArea(property);

    let mapType = process.env.REACT_APP_AUTOCOMPLETE_MAPS;

    searchTimeout = setTimeout(async () => {
      const autoSuggestionRes = await locationAutoComplete(mapType, property);
      setAreaSuggestions(autoSuggestionRes);
    }, 600);
  };

  const autoLoactionSuggestion = (lat, lon) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          let mapType = process.env.REACT_APP_AUTOCOMPLETE_MAPS;
          const currentSuggestionRes = await currentAutoLocation(mapType, pos);
          setArea(currentSuggestionRes);
          setLocationCords({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          alert(error.message);
        }
      );
    }
  };

  return (
    <div className='Discover'>
      <div className='DiscoverContainer'>
        <h1>{locale.landingPage.discover.title}</h1>
        {/* <p>Discover and book beaty & wellness professionals near you</p> */}
        <form
          className={"search" + (service || area ? " active" : "")}
          action='/search'
          autoComplete='off'>
          <input type='hidden' value='' name='category' />
          <input type='hidden' value={locationCords.latitude} name='latitude' />
          <input
            type='hidden'
            value={locationCords.longitude}
            name='longitude'
          />
          <input type='hidden' value='' name='filter' />

          <div className='input'>
            <label>{locale.landingPage.discover.searchServiceLabel}</label>
            <input
              type='text'
              placeholder={locale.landingPage.discover.searchServicePlaceholder}
              name='term'
              value={service}
              onChange={(e) => setServiceHandler(e.target.value)}
              onClick={(e) => firstInputClick(e)}
              onFocus={(e) => firstInputClick(e)}
            />
            {props.firstInputFocus && service && (
              <div className='close' onClick={deleteService}>
                <MdClose />
              </div>
            )}
            {props.firstInputFocus &&
              service.length >= 2 &&
              serviceSuggestions.length > 0 && (
                <Suggestion
                  suggestions={serviceSuggestions}
                  onSuggestionClick={(elem) => {
                    if (elem.public_profile) {
                      history.push(`/place/${elem.id}?term=${elem.name}`);
                    } else {
                      setService(elem.name);
                      dispatch(searchAutocomplete(elem.name));
                      props.handleFirstInputClick(false);
                    }
                  }}
                  autoLoactionSuggestion={autoLoactionSuggestion}
                />
              )}
          </div>
          <div className='input'>
            <label>{locale.landingPage.discover.searchCityLabel}</label>
            <input
              type='text'
              placeholder={locale.landingPage.discover.searchCityPlaceholder}
              name='location'
              value={area}
              onChange={(e) => locationSuggestion(e.target.value)}
              onClick={(e) => secondInputClick(e)}
              onFocus={(e) => secondInputClick(e)}
            />
            {props.secondInputFocus && area && (
              <div className='close' onClick={deleteArea}>
                <MdClose />
              </div>
            )}

            {props.secondInputFocus &&
              (area && areaSuggestions.length > 0 ? (
                <Suggestion
                  location
                  suggestions={areaSuggestions}
                  onSuggestionClick={(elem) => {
                    setLocationCords(elem.address);
                    setArea(elem.name);
                    props.handleSecondInputClick(false);
                  }}
                  autoLoactionSuggestion={autoLoactionSuggestion}
                />
              ) : (
                <Suggestion
                  location
                  suggestions={[]}
                  onSuggestionClick={(elem) => {
                    setArea(elem);
                    props.handleSecondInputClick(false);
                  }}
                  autoLoactionSuggestion={autoLoactionSuggestion}
                  area={area}
                  nearby
                />
              ))}
          </div>
          <button
            type='submit'
            className={
              "searchButton" +
              (props.secondInputFocus || props.firstInputFocus
                ? " focused"
                : "")
            }>
            <GrSearch />
            <span>{locale.landingPage.discover.searchBtn}</span>
          </button>
        </form>
        <div className='mobile' onClick={() => props.setStep(1)}>
          {/* <Input
            className="mobile-search"
            placeholder="What are you looking for?"
            svg
            disable
          >
            <GrSearch />
          </Input> */}
          <div className='mobDiscoverSearch'>
            <p>{locale.landingPage.discover.searchServiceLabel}</p>
            <div className='searchIconWrapper'>
              <GrSearch />
            </div>
          </div>
        </div>
        <div className='discoverBottom'>
          <span className='bolded'>
            {locale.landingPage.discover.goToB2bBoldText}
          </span>
          <span className='classic'>
            {locale.landingPage.discover.goToB2bClassic}
          </span>
          <a href={process.env.REACT_APP_B2B_LINK} className='goToB2b'>
            {locale.landingPage.discover.goToB2bLink}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Discover;
