import { locale } from "../translate/spain";

export const calculateCancellation = (value) => {
  let cancEditElem = value;
  const cancDays = Math.floor(cancEditElem / 1440);
  cancEditElem = cancEditElem - cancDays * 1440;
  const cancHours = Math.floor(cancEditElem / 60);
  cancEditElem = cancEditElem - cancHours * 60;
  const cancMins = Math.floor(cancEditElem);
  let str = "";
  cancDays > 0 ? (str += `${cancDays} ${locale.days}`) : (str += "");
  cancHours > 0
    ? (str += ` ${cancHours} 
  ${locale.hours}`)
    : (str += "");
  cancMins > 0
    ? (str += ` ${cancMins} 
  ${locale.minutes}`)
    : (str += "");
  return str;
};
