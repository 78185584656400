import React from "react";
import "./ReviewAverage.scss";
import ReviewProgress from "../ReviewProgress/ReviewProgress";
import { MdVerifiedUser } from "react-icons/md";
import { locale } from "../../../translate/spain";

const ReviewAverage = ({ place }) => {
  return (
    <>
      <div className="ReviewAverage">
        <div className="mark">
          <p>
            {place && place.average_rating}
            <span> of 5</span>
          </p>
          <span
            className="numberOfReviews"
            style={{ textTransform: "lowercase" }}
          >
            ({place && place.reviews && place.reviews.length}{" "}
            {locale.place.reviewsHeading})
          </span>
        </div>

        <div className="statistics">
          <div className="flex">
            <p>★★★★★</p>
            <div></div>
            <ReviewProgress
              completed={
                place && place.rating_grades["5"]
                  ? place.rating_grades["5"] * 100
                  : 0
              }
            />
          </div>
          <div className="flex">
            <p>★★★★</p>
            <ReviewProgress
              completed={
                place && place.rating_grades["4"]
                  ? place.rating_grades["4"] * 100
                  : 0
              }
            />
          </div>
          <div className="flex">
            <p>★★★</p>
            <ReviewProgress
              completed={
                place && place.rating_grades["3"]
                  ? place.rating_grades["3"] * 100
                  : 0
              }
            />
          </div>
          <div className="flex">
            <p>★★</p>
            <ReviewProgress
              completed={
                place && place.rating_grades["2"]
                  ? place.rating_grades["2"] * 100
                  : 0
              }
            />
          </div>
          <div className="flex">
            <p>★</p>
            <ReviewProgress
              completed={
                place && place.rating_grades["1"]
                  ? place.rating_grades["1"] * 100
                  : 0
              }
            />
          </div>
        </div>
        <div className="verifiedBy">
          <div className="verifiedHeading">
            <MdVerifiedUser />
            {locale.place.verifiedReviews}
          </div>
          <p>{locale.place.verifiedReviewsDescription}</p>
        </div>
      </div>
      <div className="verifiedBy">
        <div className="verifiedHeading">
          <MdVerifiedUser />
          {locale.place.verifiedReviews}
        </div>
        <p>{locale.place.verifiedReviewsDescription}</p>
      </div>
    </>
  );
};

export default ReviewAverage;
