import * as actionTypes from "../actions/actionTypes";

const initialState = {
  searchedPlaces: [],
  serviceTypes: [],
  places: [],
  markers: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SEARCHED_PLACES:
      return {
        ...state,
        searchedPlaces: action.payload,
      };
    case actionTypes.SET_SEARCH_AUTOCOMPLETE:
      return {
        ...state,
        serviceTypes: action.serviceTypes,
        places: action.places,
      };
    case actionTypes.SET_MARKERS:
      return {
        ...state,
        markers: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
