import React from "react";
import { locale } from "../../../translate/spain";
import "./CancellationModal.scss";

const CancellationModal = ({
  date,
  confirmCancellation,
  discardCancellation,
}) => {
  const { title, confirmButton, discardButton } =
    locale.appointment.cancellationModal;

  return (
    <>
      <div className='overlay' />
      <div className='CancellationModal'>
        <div className='header'>
          <h3>{title}</h3>
        </div>
        <div className='notesContent'>
          <p>Termin: {date}</p>
          <div className='comment'>
            <label>Unesite komentar o otkazivanju</label>
            <textarea placeholder='Komentar'></textarea>
          </div>
          <div className='termsBottom'>
            <button onClick={discardCancellation}>{discardButton}</button>
            <button onClick={confirmCancellation}>{confirmButton}</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CancellationModal;
