import * as actionTypes from "./actionTypes";
import axios from "../../utility/axios/axios";

import { startLoading, finishLoading } from "./loader";

export const setPlace = (payload) => {
  return {
    type: actionTypes.SET_PLACE,
    payload,
  };
};

export const setRecomendedPlaces = (payload) => {
  return {
    type: actionTypes.SET_RECOMENDED_PLACES,
    payload,
  };
}

export const getPlace = (id) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .get(`api/places/${id}`)
      .then(({ data }) => {
        dispatch(setPlace(data.data));
        dispatch(finishLoading());
      })
      .catch((error) => {
        console.log(error);
        dispatch(finishLoading());
      });
  };
};

export const fetchRecomendedPlaces = () => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .get(`api/recomended-places`)
      .then(({ data }) => {
        dispatch(setRecomendedPlaces(data.data));
        dispatch(finishLoading());
      })
      .catch((error) => {
        console.log(error);
        dispatch(finishLoading());
      });
  };
}