import React, { useState } from 'react'
import './WholeSignup.scss'

import {connect} from 'react-redux'
import {signup} from '../../../store/actions/account'

import Input from '../SignupInput/SignupInput'
import PrivacyAndTerms from '../PrivacyAndTerms/PrivacyAndTerms'
import AlreadyHaveAccount from '../AlreadyHaveAccount/AlreadyHaveAccount'

const WholeSignup = (props) => {
  const [user,setUser] = useState({
    email:"",
    firstName:"",
    lastName:"",
    phone:"",
    password:"",
    passwordConfirmation:""
  })
  const [showPassword, setShowPassword] = useState(false)

  const setEmailHandler = (property) => {
    setUser(u => ({...u,email:property}));
  };

  const setFirstNameHandler = (property) => {
    setUser(u => ({...u,firstName:property}));
  };

  const setLastNameHandler = (property) => {
    setUser(u => ({...u,lastName:property}));
  };

  const setPhoneHandler = (property) => {
    setUser(u => ({...u,phone:property}));
  };

  const setPasswordHandler = (property) => {
    setUser(u => ({...u,password:property}));
  };

  const setPasswordConfirmationHandler = (property) => {
    setUser(u => ({...u,passwordConfirmation:property}));
  };

  const register = (e) => {
    e.preventDefault();
    // console.log(user)
    props.signup(user.email,user.firstName,user.lastName,user.phone,user.password,user.passwordConfirmation)
  }


  return (
    <form onSubmit={register} className="WholeSignup">
      <Input label="First name" value={user.firstName} onChange={setFirstNameHandler}/>
      <Input label="Last name" value={user.lastName} onChange={setLastNameHandler}/>
      <Input label="Phone number" value={user.phone} onChange={setPhoneHandler}/>
      <Input label="E-mail" value={user.email} onChange={setEmailHandler}/>
      <Input
        label="Create Password"
        type={showPassword ? 'text' : 'password'}
        showPassword={() => setShowPassword((p) => !p)}
        value={user.password}
        onChange={setPasswordHandler}
      />
      <Input
        label="Confirm password"
        type={showPassword ? 'text' : 'password'}
        value={user.passwordConfirmation}
        onChange={setPasswordConfirmationHandler}
      />
      <button type="submit" className="agree">Agree and continue</button>
      <PrivacyAndTerms agree/>

      <AlreadyHaveAccount book={props.book}/>
    </form>
  )
}

export default connect(null,{signup})(WholeSignup)
