import React, { useState, useEffect } from "react";
import "./Booking.scss";
import { useDispatch, useSelector } from "react-redux";

import PageLayout from "../../../hoc/PageLayout/PageLayout";
import Bookings from "../../../components/Booking/Bookings/Bookings";

// import dataBookingsUpcoming from "../../../data/bookingsUpcoming.json";
// import dataBookingsFinished from "../../../data/bookingsFinished.json";
import { getAppointments } from "../../../store/actions/booking";
import { locale } from "../../../translate/spain";

const Booking = (props) => {
  const dispatch = useDispatch();
  const [bookingType, setBookingType] = useState('ACTIVE');

  useEffect(() => {
      dispatch(getAppointments(bookingType));
    // eslint-disable-next-line
  }, [bookingType]);

  const finishedBookings = useSelector((state) => state.booking.nonActive);
  const activeBookings = useSelector((state) => state.booking.active);
  const cancelledBooking = useSelector((state) => state.booking.cancelled);

  return (
    <PageLayout>
      <div className="Booking">
        <div className="sticky-container">
          <div className="sticky">
            <p className="title">{locale.myBookings.title}</p>

            <div className="links">
              <button
                className={bookingType === 'ACTIVE' && " active"}
                onClick={() => setBookingType('ACTIVE')}
              >
                {locale.myBookings.upcomingBookings}
              </button>
              <button
                className={bookingType === 'FINISHED' && " active"}
                onClick={() => setBookingType('FINISHED')}
              >
                {locale.myBookings.finishedBookings}
              </button>
              <button
                className={bookingType === 'CANCELED' && " active"}
                onClick={() => setBookingType('CANCELED')}
              >
                {locale.myBookings.cancelledBookings} 
              </button>
            </div>
          </div>
        </div>

        <div className="out-container">
          <div className="container">
            <Bookings
              bookings={bookingType === 'ACTIVE' ?  activeBookings : bookingType === 'FINISHED' ?  finishedBookings : cancelledBooking}
              type={bookingType === 'ACTIVE' ?  'upcoming' : bookingType === 'FINISHED' ?  'finished' : 'canceled'}
            />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default Booking;
