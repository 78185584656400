import React, { useState } from "react";
import "./SortBy.scss";

import { MdKeyboardArrowDown, MdCheck } from "react-icons/md";
import { locale } from "../../../translate/spain";

const SortBy = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="SortBy" onClick={() => setOpen((p) => !p)}>
      <span>{locale.searchPage.sortBy}:</span>
      <p>{props.filter === 'Nearest' ? locale.searchPage.nearest : locale.searchPage.bestRated}</p>
      <MdKeyboardArrowDown />

      {open && (
        <div className="filters">
          <div
            className={"choice" + (props.filter === "Nearest" ? " active" : "")}
            onClick={() => props.setFilter("Nearest")}
          >
            <p>{locale.searchPage.nearest}</p>
            <div className="checkbox">
              <MdCheck />
            </div>
          </div>
          <div
            className={
              "choice" + (props.filter === "Best rated" ? " active" : "")
            }
            onClick={() => props.setFilter("Best rated")}
          >
            <p>{locale.searchPage.bestRated}</p>
            <div className="checkbox">
              <MdCheck />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SortBy;
