export const LOGIN = "LOGIN";
export const SET_PROFILE = "SET_PROFILE";
export const DISCARD_PROFILE = "DISCARD_PROFILE";
export const NOT_AUTHORIZED = "NOT_AUTHORIZED";

export const FETCH_CATEGORIES = "FETCH_CATEGORIES";

export const START_LOADING = "START_LOADING";
export const FINISH_LOADING = "FINISH_LOADING";

export const SUCCESS_NOTIFICATION = "SUCCESS_NOTIFICATION";
export const ERROR_NOTIFICATION = "ERROR_NOTIFICATION";

export const SET_FAVORITES = "SET_FAVORITES";

export const SET_PLACE = "SET_PLACE";
export const SET_RECOMENDED_PLACES = "SET_RECOMENDED_PLACES";

export const SET_SEARCHED_PLACES = "SET_SEARCHED_PLACES";

export const SET_FREE_SLOTS = "SET_FREE_SLOTS";

export const SET_ACTIVE_APPOINTMENTS = "SET_ACTIVE_APPOINTMENTS";
export const SET_NONACTIVE_APPOINTMENTS = "SET_NONACTIVE_APPOINTMENTS";
export const SET_CANCELLED_APPOINTMENTS = "SET_CANCELLED_APPOINTMENTS";

export const SET_MARKERS = "SET_MARKERS";

export const SET_SEARCH_AUTOCOMPLETE = "SET_SEARCH_AUTOCOMPLETE";
