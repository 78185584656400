import React, { useState, useEffect } from "react";
import "./SearchResult.scss";

import Stores from "../../Universal/Stores/Stores";

import Map from "../Map/Map";
import SortBy from "../SortBy/SortBy";
import Toggle from "../../Utils/Toggle/Toggle";
import Input from "../../Signup/SignupInput/SignupInput";
import { GoSearch } from "react-icons/go";
import { GrClose } from "react-icons/gr";
import { FiMap, FiMapPin } from "react-icons/fi";
import { MdCheck } from "react-icons/md";

// translate
import { locale } from "../../../translate/spain";

const SearchResult = (props) => {
  const [showMap, setShowMap] = useState(false);
  const [filter, setFilter] = useState("Nearest");
  const [editSearch, setEditSearch] = useState(false);
  const [type, setType] = useState(props.term);
  const [city, setCity] = useState(
    props.location ? props.location : locale.searchPage.nearMe
  );
  const [mapShown, setMapShown] = useState(false);
  const [updateResults, setUpdateResults] = useState(true);

  useEffect(() => {
    setType(props.term);
    setCity(props.location ? props.location : locale.searchPage.nearMe);
  }, [props.term, props.location]);

  const typeChangeHandler = (property) => {
    setType(property);
  };

  const cityChangeHandler = (property) => {
    setCity(property);
  };

  const termSearch = props.term;

  useEffect(() => {
    const listener = () => {
      const windowY = window.scrollY;
      if (windowY > 60) {
        document.querySelector(".title").className = "title scroll";
      } else {
        document.querySelector(".title").className = "title";
      }
    };
    window.addEventListener("scroll", listener);

    return () => window.removeEventListener("scroll", listener);
  }, []);

  return (
    <div className='SearchResult' onClick={props.onClick}>
      <div
        className='searchMobile'
        onClick={() => {
          setEditSearch(true);
        }}>
        <Input
          className='searchMobileInput'
          placeholder={locale.landingPage.discover.searchServicePlaceholder}
          svg
          disable
          value={props.term}>
          <GoSearch color='#1A73E8' className='searchIcon' />
          <div
            className='searchMobileMap'
            onClick={(e) => {
              e.stopPropagation();
              setMapShown(true);
            }}>
            <FiMap color='#516F90' />
          </div>
        </Input>
      </div>
      {editSearch && (
        <div className='editSearch'>
          <div className='editSearchContent'>
            <div className='flex'>
              <h4>{locale.searchPage.editSearch}</h4>
              <GrClose onClick={() => setEditSearch(false)} />
            </div>
            <div className='inputContainer'>
              <form action='/search'>
                <input type='hidden' value='' name='category' />
                <Input
                  className='editInput'
                  placeholder={
                    locale.landingPage.discover.searchServicePlaceholder
                  }
                  name='term'
                  svg
                  onChange={typeChangeHandler}
                  value={type}>
                  <GoSearch color='#1A73E8' className='searchIcon' />
                </Input>
                <div className='secondInput'>
                  <Input
                    className='editInput'
                    placeholder={
                      locale.landingPage.discover.searchCityPlaceholder
                    }
                    name='location'
                    svg
                    onChange={cityChangeHandler}
                    value={city}>
                    <FiMapPin color='#1A73E8' className='pinIcon' />
                  </Input>
                </div>
                <button type='submit'>
                  {locale.landingPage.discover.searchBtn}
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
      <div className='title'>
        <div className='titleContainer'>
          <span>
            {termSearch} •{" "}
            {props.location ? props.location : locale.searchPage.nearMe}
          </span>
          <h3>
            {props.stores.length} {locale.searchPage.results}{" "}
            {termSearch && `${locale.searchPage.for} ${termSearch} `}
            {props.location
              ? `${locale.searchPage.in} ${props.location}`
              : locale.searchPage.nearMe}
          </h3>
          <div className='flex'>
            <SortBy
              filter={filter}
              setFilter={(filterType) => {
                setFilter(filterType);
                props.searchbyFilter(filterType);
              }}
            />
            {props.stores && props.stores[0]?.latitude && (
              <div className='showMap'>
                <span>{locale.searchPage.showMap}</span>
                <Toggle active={showMap} toggle={() => setShowMap((p) => !p)} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='titleMobile'>
          <h3>
            {props.stores.length} {locale.searchPage.results}{" "}
            {termSearch && `${locale.searchPage.for} ${termSearch} `}
            {props.location
              ? `${locale.searchPage.in} ${props.location}`
              : locale.searchPage.nearMe}
          </h3>
        </div>

        <div className={"content" + (showMap ? " show-map" : "")}>
          <Stores stores={props.stores} type={type} city={city} search />
          <div className='map'>
            {showMap && (
              <Map
                zoomEnabled
                term={props.term.length > 0 ? props.term : null}
                latitude={
                  props.stores.length > 0
                    ? props.stores[0].latitude
                    : 43.3180512
                }
                longitude={
                  props.stores.length > 0
                    ? props.stores[0].longitude
                    : 21.8919618
                }
              />
            )}
          </div>
        </div>
      </div>
      {mapShown && (
        <div className='mobileMap'>
          <Map
            zoomEnabled
            latitude={
              props.stores.length > 0 ? props.stores[0].latitude : 43.3180512
            }
            longitude={
              props.stores.length > 0 ? props.stores[0].longitude : 21.8919618
            }
          />
          <div className='flex'>
            <div className='left' onClick={() => setUpdateResults((p) => !p)}>
              <div className={"checkbox" + (updateResults ? " active" : "")}>
                <MdCheck />
              </div>
              <p>{locale.searchPage.updateResultsWhenMovingMap}</p>
            </div>
            <div
              className='right'
              onClick={() => {
                setMapShown(false);
              }}>
              <GrClose />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchResult;
