import React from "react";
import { Link } from "react-router-dom";
import "./Areas.scss";

const Area = (props) => {
  const areasList = props.areas.map((area) => (
    <Link
      key={area.id}
      className="Area"
      to={`search?category=${area.id}&term=&location=`}>
      <div className="image">
        <img src={area.image.url} alt={area.name} loading="lazy" />
      </div>

      <div className="description">
        <h3>{area.name}</h3>
      </div>
    </Link>
  ));
  return <div className="Areas">{areasList}</div>;
};

export default Area;
