import React from "react";
import "./Bookings.scss";

import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { returnCharsName } from "../../../helpers/nameLength";
import noUpcomingBookings from "../../../assets/Book/noUpcomingBookings.png";
import noFinishedBookings from "../../../assets/Book/noFinishedBookings.png";
import { locale } from "../../../translate/spain";

const Bookings = (props) => {
  const daysNames = [
    locale.days3letters.sunday,
    locale.days3letters.monday,
    locale.days3letters.tuesday,
    locale.days3letters.wednesday,
    locale.days3letters.thursday,
    locale.days3letters.friday,
    locale.days3letters.saturday,
  ];

  const bookingsList =
    props.bookings.length > 0 ? (
      props.bookings.map((booking) => (
        <Link
          to={`/bookings/${props.type}/${booking.place.id}/${booking.id}`}
          className="SingleBooking"
        >
          <div className="date">
            <span>{daysNames[dayjs(booking.date).day()]}</span>
            <p>{dayjs(booking.date).format("DD")}</p>
            <span>{dayjs(booking.date).format("MMM")}</span>
          </div>

          <div className="description">
            <p className={"event " + booking.status}>
              {booking.status === "ACTIVE"
                ? locale.myBookings.upcomingBookings
                : booking.status}
            </p>
            <p className="service">
              {returnCharsName(booking.service.name, 30)}
            </p>
            <span>{`${booking.from.slice(0, -3)}-${booking.to.slice(
              0,
              -3
            )}`}</span>
            <p>{booking.place.name}</p>
          </div>
        </Link>
      ))
    ) : props.type === "upcoming" ? (
      <div className="no_bookings">
        <img src={noUpcomingBookings} alt="No bookings" />
        <h1>{locale.myBookings.noUpcomingBookingsTitle}</h1>
        <p>{locale.myBookings.noUpcomingBookingsDesc}</p>
        <Link to="/" className="backBtn">
          {locale.myBookings.makeBookings}
        </Link>
      </div>
    ) : (
      <div className="no_bookings">
        <img src={noFinishedBookings} alt="No bookings" />
        <h1>{locale.myBookings.noFinishedBookingsTitle}</h1>
        <p>{locale.myBookings.noFinishedBookingsDesc}</p>
        <Link to="/" className="backBtn">
          {locale.myBookings.makeBookings}
        </Link>
      </div>
    );

  return <div className="Bookings">{bookingsList}</div>;
};

export default Bookings;
