import React from 'react'
import './Team.scss'

const team = props => {
    const workers = props.team.map(worker => (
        <div className="worker">
        <div className="image">

        </div>
        <h5>{worker.name}</h5>
        <a href={"mailto:" + worker.email}>{worker.email}</a>
        </div>
    ))

    return (<div className="Team">
        {workers}
    </div>)
}

export default team