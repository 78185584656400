import * as actionTypes from "../actions/actionTypes";

const initialState = {
  authorized: false,
  profile: null,
  // authorized:true,
  // profile: {
  //     first_name: "Christian",
  //     last_name: "Jenrail",
  //     email:"admin@example.com"
  // }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PROFILE:
      return {
        ...state,
        authorized: true,
        profile: action.profile,
      };
    case actionTypes.DISCARD_PROFILE:
      return {
        ...state,
        authorized: false,
        profile: null,
      };
    case actionTypes.NOT_AUTHORIZED:
      return {
        ...state,
        authorized: false,
      };
    default:
      return state;
  }
};

export default reducer;
