import * as actionTypes from "../actions/actionTypes";

const initialState = {
  place: null,
  recomendedPlaces: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PLACE:
      return {
        ...state,
        place: action.payload,
      };
    case actionTypes.SET_RECOMENDED_PLACES:
    return {
      ...state,
      recomendedPlaces: action.payload,
    };
    default:
      return state;
  }
};

export default reducer;
