import React from 'react'
import './DeleteAccount.scss'

import {connect, useSelector} from 'react-redux'

import {deleteProfile} from '../../../store/actions/account'
import { MdClose } from 'react-icons/md'

const DeleteAccount = (props) => {
  const profile = useSelector((state) => state.account.profile)

  const deleteProfileHandler = () => {
    props.deleteProfile(profile.id,props.close);
  }

  return (
    <div className="DeleteAccount">
      <div className="overlay" onClick={props.close} />
      <div className="slider">
        <div className="header">
          <p>{props.translate.title}</p>
          <MdClose onClick={props.close} />
        </div>

        <div className="container">
          <p>{props.translate.description}</p>

          <div className="buttons">
            <button onClick={deleteProfileHandler}>{props.translate.confirmButton}</button>
            <button onClick={props.close}>{props.translate.declineButton}</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(null,{deleteProfile})(DeleteAccount);
