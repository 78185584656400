import React from "react";
import "./Suggestion.scss";

import { GrSearch, GrLocation } from "react-icons/gr";
import { BiStore } from "react-icons/bi";
import nearby from "../../../assets/LandingPage/nearby.png";
import { locale } from "../../../translate/spain";

const Suggestion = (props) => {
  const suggestionList = props.suggestions.map((suggestion) => (
    <div
      key={suggestion.id}
      className='button'
      onClick={(e) => {
        e.stopPropagation();
        props.onSuggestionClick && props.onSuggestionClick(suggestion);
      }}>
      <div className='image'>
        {suggestion.public_profile ? (
          <BiStore color='#99ACC2' />
        ) : props.location ? (
          <GrLocation />
        ) : (
          <GrSearch />
        )}
      </div>
      <p>{suggestion.name ? suggestion.name : "New York"}</p>
    </div>
  ));

  return (
    <div className={"Suggestion" + (props.nav ? " nav" : "")}>
      {props.nearby ? (
        <div
          className='button'
          onClick={(e) => {
            props.autoLoactionSuggestion && props.autoLoactionSuggestion();
            // e.stopPropagation();
            // props.onSuggestionClick(locale.searchPage.nearMe);
          }}>
          <div className='image'>
            <img src={nearby} alt={locale.searchPage.nearMe} />
          </div>
          <p>{locale.searchPage.nearMe}</p>
        </div>
      ) : (
        suggestionList
      )}
    </div>
  );
};

export default Suggestion;
