import React from "react";
import "./BookingDate.scss";

import { FiCalendar } from "react-icons/fi";
import dayjs from "dayjs";
import { locale } from "../../../translate/spain";

const bookingDate = (props) => {
  return (
    <div className="BookingDate">
      <div className="date">
        <FiCalendar />
        <p>
          {dayjs(props.date).format("DD MMM YYYY")} {locale.myBookings.at}{" "}
          {props.time.substring(0, 5)}
        </p>
      </div>

      {/* {props.calendar && <button>Add to my calendar</button>} */}
    </div>
  );
};

export default bookingDate;
