import axios from "axios";

export const locationAutoComplete = async (type, property) => {
  if (type === "here") {
    const res = await hereMapsAutoComplete(property);
    let responseMaps = res.data.items;
    let convertedData = convertResponse(type, responseMaps);
    return convertedData;
  } else {
    const res = await nominatimMapsAutoComplete(property);
    let responseMaps = res.data;
    let convertedData = convertResponse(type, responseMaps);
    return convertedData;
  }
};

const hereMapsAutoComplete = (property) => {
  return axios.get(
    `https://geocode.search.hereapi.com/v1/geocode?&q=${property}&apiKey=${process.env.REACT_APP_HERE_MAPS_API_KEY}&`
  );
};

const nominatimMapsAutoComplete = (property) => {
  return axios.get(
    `https://nominatim.openstreetmap.org/?addressdetails=1&q=${property}&format=json&limit=5`
  );
};

const convertResponse = (type, data) => {
  if (type === "here") {
    const convertedData = data.map((item) => {
      return {
        address: {
          latitude: item.position.lat,
          longitude: item.position.lng,
        },
        name: item.address.label,
      };
    });
    return convertedData;
  } else {
    const convertedData = data.map((item) => {
      return {
        address: {
          latitude: item.lat,
          longitude: item.lon,
        },
        name: item.display_name,
      };
    });
    return convertedData;
  }
};

export const currentAutoLocation = async (type, property) => {
  if (type === "here") {
    try {
      const res = await hereMapscurrentAutoLocation(property);
      let responseMaps = res.data.items[0];
      return responseMaps.title;
    } catch (error) {
      console.log("error", error);
    }
  } else {
    const res = await nominatimMapscurrentAutoLocation(property);
    let responseMaps = res.data;
    return responseMaps.display_name;
  }
};

const hereMapscurrentAutoLocation = (position) => {
  return axios.get(
    `https://revgeocode.search.hereapi.com/v1/revgeocode?at=${position.lat},${position.lng}&apiKey=${process.env.REACT_APP_HERE_MAPS_API_KEY}`
  );
};

const nominatimMapscurrentAutoLocation = (position) => {
  return axios.get(
    // `https://nominatim.openstreetmap.org/reverse?lat=43.3177393&lon=21.8887254&json_callback=<string>`
    `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${position.lat}&lon=${position.lng}`
  );
};
