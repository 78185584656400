import * as actionTypes from "./actionTypes";
import axios from "../../utility/axios/axios";
import { startLoading, finishLoading } from "./loader";
import { successNotification, errorsNotification } from "./notification";

export const setFreeSlots = (freeSlots) => {
  return {
    type: actionTypes.SET_FREE_SLOTS,
    freeSlots,
  };
};

export const getFreeSlots = (service_id, calendar_id) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post("/api/free-slots", {
        service_id,
        calendar_id,
      })
      .then((response) => {
        dispatch(setFreeSlots(response.data.data));
        dispatch(finishLoading());
      })
      .catch((error) => {
        console.log("ERROR GET FREE SLOTS", error.response);
        dispatch(finishLoading());
      });
  };
};

export const createAppointment = (
  date,
  from,
  service_id,
  calendar_id,
  bookingNotes,
  required_info,
  history
) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post("/api/create-appointment", {
        date,
        from,
        service_id,
        calendar_id,
        required_info,
        notes: bookingNotes,
      })
      .then((response) => {
        dispatch(finishLoading());
        dispatch(successNotification(response.data.message));
        history.goBack();
      })
      .catch((error) => {
        if (error.response.status === 422) {
          Object.keys(error.response.data.errors).map((errorData) =>
            error.response.data.errors[errorData].map((message) =>
              dispatch(errorsNotification(message))
            )
          );
        } else dispatch(errorsNotification(error.response.data.message));

        dispatch(finishLoading());
      });
  };
};

export const setNonActiveAppointments = (nonActive) => {
  return {
    type: actionTypes.SET_NONACTIVE_APPOINTMENTS,
    nonActive,
  };
};

export const setActiveAppointments = (active) => {
  return {
    type: actionTypes.SET_ACTIVE_APPOINTMENTS,
    active,
  };
};

export const setCancelledAppointmens = (cancelled) => {
  return {
    type: actionTypes.SET_CANCELLED_APPOINTMENTS,
    cancelled,
  };
};

export const getAppointments = (status) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .get(`/api/get-appointments?status=${status}`)
      .then((response) => {
        if (status === "ACTIVE") {
          dispatch(setActiveAppointments(response.data.data));
        } else if (status === "FINISHED") {
          dispatch(setNonActiveAppointments(response.data.data));
        } else {
          dispatch(setCancelledAppointmens(response.data.data));
        }
        dispatch(finishLoading());
      })
      .catch((error) => {
        console.log("ERROR GET APPOINTMENTS", error.response);
        dispatch(finishLoading());
      });
  };
};

export const cancelAppointment = (cancellation_code, history, callback) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post("/api/cancel-appointment", { cancellation_code })
      .then((response) => {
        dispatch(finishLoading());
        dispatch(successNotification(response.data.message));
        history && history.goBack();
        callback && callback();
      })
      .catch((error) => {
        console.log("ERROR cancelAppointment", error.response);
        dispatch(errorsNotification(error.response.data.message));
        dispatch(finishLoading());
      });
  };
};

export const getCanceledAppointment = (cancellation_token, callback) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .get(`/api/canceled-appointment/from-token/${cancellation_token}`)
      .then((response) => {
        dispatch(finishLoading());
        // dispatch(successNotification(response.data.message));
        callback && callback(response.data.data);
      })
      .catch((error) => {
        console.log("ERROR getCanceledAppointment", error.response);
        dispatch(errorsNotification(error.response.data.message));
        dispatch(finishLoading());
      });
  };
};
