import React from "react";
import "./BookNavigation.scss";

import { HiArrowLeft } from "react-icons/hi";
import { MdClose } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { locale } from "../../../translate/spain";

const BookNavigation = (props) => {
  let history = useHistory();

  const title = () => {
    switch (props.step) {
      case 1:
        return locale.book.chooseService;
      case 2:
        return locale.book.chooseStaff;
      case 3:
        return locale.book.chooseTime;
      case 4:
        if (props.loggedIn) {
          return locale.book.reviewAndConfirm;
        } else if (!props.signup) {
          return locale.book.loginToContinue;
        }
        return locale.book.signupToContinue;

      default:
        return "";
    }
  };
  return (
    <div className="BookNavigation">
      <div className="container">
        <div className="title">
          {props.step > 2 && (
            <HiArrowLeft className="back" onClick={props.previousStep} />
          )}

          <div className="step">
            <span>
              {locale.book.step}{" "}
              {props.step === 2 || props.step === 1 ? 1 : props.step - 1}{" "}
              {locale.book.of} 3
            </span>
            <h2>{title()}</h2>
          </div>
        </div>

        <MdClose onClick={() => history.goBack()} />
      </div>
    </div>
  );
};

export default BookNavigation;
