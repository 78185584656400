import React, { useState, useEffect } from "react";
import "./Book.scss";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import BookNavigation from "../../../components/Book/BookNavigation/BookNavigation";
import Services from "../../../components/Signup/ServicesDisplay/ServicesDisplay";
import Personell from "../../../components/Place/Personell/Personell";
import WholeLogin from "../../../components/Login/WholeLogin/WholeLogin";
import WholeSignup from "../../../components/Signup/WholeSignup/WholeSignup";
import Ticket from "../../../components/Booking/Ticket/Ticket";
import BookReview from "../../../components/Book/BookReview/BookReview";
import BookCalendar from "../../../components/Book/BookCalendar/BookCalendar";
import Terms from "../../../components/Booking/Terms/Terms";

// import dataPlace from "../../../data/place.json";
// import dataPersonell from "../../../data/personell.json";
import { getPlace } from "../../../store/actions/place";
import {
  getFreeSlots,
  createAppointment,
} from "../../../store/actions/booking";
import { locale } from "../../../translate/spain";

const Book = (props) => {
  const [step, setStep] = useState(1);
  const [service, setService] = useState({
    service: null,
    duration: null,
    price: null,
    service_id: null,
  });
  const [worker, setWorker] = useState(null);
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [agree, setAgree] = useState(false);
  const [skipStep2, setSkipStep2] = useState(false);
  const [bookingNotes, setBookingNotes] = useState("");
  const [notesOpen, setNotesOpen] = useState("");
  const [termsOpen, setTermsOpen] = useState("");

  const [signup, setSignup] = useState(false);

  const dispatch = useDispatch();
  const { id } = useParams();
  const { place } = useSelector((state) => state.place);

  const [localPlace, setLocalPlace] = useState(place);
  const [otherKey, setOtherKey] = useState("");
  const [required_info, setRequiredInfo] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address: "",
    zip_code: "",
    city: "",
    [otherKey]: "",
  });

  useEffect(() => {
    dispatch(getPlace(id));

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    const serviceId = query.get("service_id");
    const serviceName = query.get("service_name");
    const serviceDuration = query.get("duration");
    const servicePrice = query.get("price");
    if (serviceId && serviceName && serviceDuration && servicePrice) {
      setService({
        service: serviceName,
        duration: serviceDuration,
        price: servicePrice,
        service_id: parseInt(serviceId),
      });
      setStep(2);
    }
    const workerId = query.get("worker");
    if (workerId && place) {
      const wrk = place.staff.find((elem) => elem.id === parseInt(workerId));
      setWorker(wrk);
      setSkipStep2(true);
      let updatedPlace = place;
      updatedPlace.services = place.services.map((category) => ({
        ...category,
        services: category.services.filter((catService) =>
          wrk.services.some((el) => el.id === catService.id)
        ),
      }));
      setLocalPlace(updatedPlace);
    }
    //eslint-disable-next-line
  }, [place]);

  const chooseService = (ser, dur, price, ser_id) => {
    setService({
      service: ser,
      duration: dur,
      price: price,
      service_id: ser_id,
    });
    if (skipStep2) {
      setStep(3);
      dispatch(getFreeSlots(ser_id, worker.calendar.id));
    } else {
      setStep(2);
    }
  };

  const lastPage = () => {
    if (step === 4) {
      if (props.profile) {
        return (
          <BookReview
            place={place}
            service={service}
            date={date}
            time={time}
            name={props.profile.first_name + " " + props.profile.last_name}
            worker={worker}
            agree={agree}
            setAgree={setAgree}
            otherKey={otherKey}
            setOtherKey={(value) => setOtherKey(value)}
            required_info={required_info}
            setRequiredInfo={(value) => setRequiredInfo(value)}
            setBookingNotes={(value) => setBookingNotes(value)}
            bookingNotes={bookingNotes}
            notesOpen={notesOpen}
            setNotesOpen={setNotesOpen}
            termsOpen={termsOpen}
            setTermsOpen={setTermsOpen}
          />
        );
      } else if (!signup) {
        return <WholeLogin back book={() => setSignup(true)} />;
      } else return <WholeSignup book={() => setSignup(false)} />;
    }
    return null;
  };

  const prevStepHandler = () => {
    if (step === 2) {
      setService({
        service: null,
        duration: null,
        price: null,
        service_id: null,
      });
    } else if (step === 3) {
      if (skipStep2) {
        setService({
          service: null,
          duration: null,
          price: null,
          service_id: null,
        });
        setStep(1);
        return;
      } else {
        setWorker(null);
      }
      setWorker(null);
    } else if (step === 4) {
      setDate(null);
      setTime(null);
    }
    setStep((p) => p - 1);
  };

  const selectSlotHandler = (date, time) => {
    setDate(date);
    setTime(time);
    setStep(4);
  };

  return (
    <>
      <div className="Book">
        <BookNavigation
          step={step}
          previousStep={prevStepHandler}
          loggedIn={props.profile ? true : false}
          signup={signup}
        />

        <div className="container">
          <div className="main">
            {step === 1 && (
              <Services
                choose={{ ...service }}
                services={localPlace ? localPlace.services : []}
                choosen={service}
                chooseService={chooseService}
              />
            )}
            {step === 2 && (
              <Personell
                persons={place ? place.staff : []}
                service_id={service.service_id}
                select={(person) => {
                  setStep(3);
                  setWorker(person);
                  dispatch(
                    getFreeSlots(service.service_id, person.calendar.id)
                  );
                }}
              />
            )}
            {step === 3 && (
              <BookCalendar
                select={(date, time) => selectSlotHandler(date, time)}
              />
            )}

            {lastPage()}
          </div>

          {!termsOpen && (
            <div className="receipt">
              <Ticket
                place={place}
                service={service}
                worker={worker}
                date={date}
                time={time}
                paymentMethod={step === 4}
              />
              <div className="bottom"></div>
            </div>
          )}
        </div>

        {/* {step === 1 && (
        <div className="continue">
          <button onClick={() => setStep(2)}>Continue</button>
        </div>
      )} */}

        {step === 4 && !notesOpen && !termsOpen && props.profile && (
          <div className="confirm-book">
            <button
              disabled={!agree}
              onClick={() =>
                dispatch(
                  createAppointment(
                    date,
                    time.slice(0, -3),
                    service.service_id,
                    worker.calendar.id,
                    bookingNotes,
                    required_info,
                    props.history
                  )
                )
              }>
              {locale.book.confirmAndBook}
            </button>
            <p>
              {locale.book.byClicking}
              <Link>{locale.links.privacyPolicy}</Link> {locale.auth.and}{" "}
              <Link>{locale.links.termsAndConditions}</Link>.
            </p>
          </div>
        )}
        {termsOpen && (
          <Terms
            toc={worker && worker.calendar.toc}
            onSave={() => {
              setAgree(true);
              setTermsOpen(false);
            }}
            back={() => setTermsOpen(false)}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.account.profile,
  };
};

export default connect(mapStateToProps)(Book);
