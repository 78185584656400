import React, { useState } from "react";
import "./AboutUsAndDetails.scss";

import { FaPhoneAlt } from "react-icons/fa";
import { HiMail } from "react-icons/hi";
// import { ImLocation } from "react-icons/im";
// import { FaDirections } from "react-icons/fa";

import Map from "../../../components/Search/Map/Map";
import OpeningHours from "../OpeningHours/OpeningHours";
import { returnCharsName } from "../../../helpers/nameLength";
import { locale } from "../../../translate/spain";

const AboutUsAndDetails = ({ place, term }) => {
  const [numberShowed, setNumbeShowed] = useState(false);
  const [emailShowed, setEmailShowed] = useState(false);
  const [showWholeDesc, setShowWholeDesc] = useState(false);

  return (
    <div className='AboutUsAndDetails'>
      <div className='container'>
        {place && place.latitude && place.longitude && (
          <Map
            latitude={place.latitude}
            longitude={place.longitude}
            term={term}
          /> // if you wanna disable zoom just send (placeMap) param like a props
        )}
        {/* <div className='mapHelper'>
          <ImLocation size={42} color='#253342' style={{ paddingBottom: 10 }} />
          <div className='locationHelper'>
            <p className='locationText'>{place && place.address}</p>
            <div className='direction'>
              <FaDirections size={23} color='#253342' />
            </div>
          </div>
        </div> */}
        <div className='about-us'>
          <h5>{locale.placeAboutUs.aboutUs}</h5>
          {place.description && (
            <p>
              {place.description.length > 85
                ? showWholeDesc
                  ? place.description
                  : returnCharsName(place.description, 85)
                : place.description}
            </p>
          )}
          {!showWholeDesc &&
            place.description &&
            place.description.length > 85 && (
              <a href='#!' onClick={() => setShowWholeDesc(true)}>
                {locale.showMore}
              </a>
            )}
        </div>

        <div className='details-and-contact'>
          <OpeningHours hours={place && place.opening_hours} />

          <div className='with-icon' onClick={() => setNumbeShowed(true)}>
            {!numberShowed && (
              <div className='icon'>
                <FaPhoneAlt size={20} />
              </div>
            )}

            <div className='description'>
              {!numberShowed && <p>{locale.placeAboutUs.showNumber}</p>}
              {numberShowed && (
                <a href={`tel:${place && place.phone}`}>
                  {place && place.phone}
                </a>
              )}
            </div>
          </div>

          <div className='with-icon' onClick={() => setEmailShowed(true)}>
            {!emailShowed && (
              <div className='icon'>
                <HiMail size={20} />
              </div>
            )}
            <div className='description'>
              {!emailShowed && <p>{locale.placeAboutUs.showEmail}</p>}
              {emailShowed && (
                <a href={`mailto:${place && place.email}`}>
                  {place && place.email}
                </a>
              )}
            </div>
          </div>

          {/* <div className="with-icon">
            <div className="icon">
              <HiLocationMarker />
            </div>

            <div className="description">
              <a href="!#">Adress XX , Postal code , City</a>
            </div>
          </div> */}

          {/* <div className="map">
            <Map />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default AboutUsAndDetails;
