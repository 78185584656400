import axios from 'axios'
import dotenv from 'dotenv';

dotenv.config();

const axiosConfig = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    withCredentials:true
})

export default axiosConfig
