import React from "react";
import { Link } from "react-router-dom";
import PageNotFoundImg from "../../../assets/layout/404-page.png";
import "./NotFoundPage.scss";

class NotFoundPage extends React.Component {
  render() {
    return (
      <div className='NotFoundPage'>
        <img src={PageNotFoundImg} />

        <Link to='/'>Go to Home </Link>
      </div>
    );
  }
}
export default NotFoundPage;
