import * as actionTypes from "./actionTypes";
import axios from "../../utility/axios/axios";

import { startLoading, finishLoading } from "./loader";
import { successNotification, errorsNotification } from "./notification";
import { addToFavorites } from "./favorites";

export const discardProfile = () => {
  return {
    type: actionTypes.DISCARD_PROFILE,
  };
};

export const setProfile = (data) => {
  return {
    type: actionTypes.SET_PROFILE,
    profile: data,
  };
};

export const notAuthorized = () => {
  return {
    type: actionTypes.NOT_AUTHORIZED,
  };
};

export const login = (email, password, type, back, history) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .get("/sanctum/csrf-cookie")
      .then((res) => {
        axios
          .post("/login", { email: email, password: password })
          .then((response) => {
            dispatch(getProfile());
            dispatch(successNotification(response.data.message));
            dispatch(finishLoading());

            if (type !== "auth" && !back) {
              // console.log(typeof type);
              dispatch(addToFavorites(type));
              history.goBack();
            }
            history && history.push("/");
          })
          .catch((error) => {
            dispatch(errorsNotification(error.response.data.message));
            dispatch(finishLoading());
          });
      })
      .catch((error) => {
        dispatch(finishLoading());
      });
  };
};

export const signup = (
  email,
  firstName,
  lastName,
  phone,
  password,
  passwordConfirmation,
  history
) => {
  return (dispatch) => {
    dispatch(startLoading());

    axios
      .get("/sanctum/csrf-cookie")
      .then((res) => {
        axios
          .post("/register", {
            email: email,
            first_name: firstName,
            last_name: lastName,
            phone: phone,
            password: password,
            password_confirmation: passwordConfirmation,
          })
          .then((response) => {
            dispatch(setProfile(response.data.data));
            dispatch(successNotification(response.data.message));
            dispatch(finishLoading());
            history && history.push("/");
          })
          .catch((error) => {
            if (error.response.status === 422) {
              Object.keys(error.response.data.errors).map((errorData) =>
                error.response.data.errors[errorData].map((message) =>
                  dispatch(errorsNotification(message))
                )
              );
            } else dispatch(errorsNotification(error.response.data.message));

            dispatch(finishLoading());
          });
      })
      .catch((error) => {
        dispatch(finishLoading());
      });
  };
};

export const deleteAvatar = () => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .delete("/api/user/avatar")
      .then((response) => {
        dispatch(setProfile(response.data.data));
        dispatch(successNotification(response.data.message));
        dispatch(finishLoading());
      })
      .catch((error) => {
        dispatch(errorsNotification(error.response.data.message));
        dispatch(finishLoading());
      });
  };
};

export const uploadAvatar = (picture) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post("/api/user/avatar", picture)
      .then((response) => {
        dispatch(setProfile(response.data.data));
        dispatch(successNotification(response.data.message));
        dispatch(finishLoading());
      })
      .catch((error) => {
        dispatch(errorsNotification(error.response.data.message));
        dispatch(finishLoading());
      });
  };
};

export const changePassword = (currentPassword, newPassword, callback) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post("/api/user/change-password", {
        current_password: currentPassword,
        new_password: newPassword,
      })
      .then((response) => {
        dispatch(successNotification(response.data.message));
        dispatch(finishLoading());
        callback();
      })
      .catch((error) => {
        dispatch(errorsNotification(error.response.data.message));
        dispatch(finishLoading());
      });
  };
};

export const changeEmail = (email, callback) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post("/api/user/change-email", { email: email })
      .then((response) => {
        dispatch(setProfile(response.data.data));
        dispatch(successNotification(response.data.message));
        dispatch(finishLoading());
        callback();
      })
      .catch((error) => {
        dispatch(errorsNotification(error.response.data.message));
        dispatch(finishLoading());
      });
  };
};

export const updateProfile = (firstName, lastName, phone, address) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post("/api/user/profile", {
        first_name: firstName,
        last_name: lastName,
        phone: phone,
        address,
      })
      .then((response) => {
        dispatch(setProfile(response.data.data));
        dispatch(successNotification(response.data.message));
        dispatch(finishLoading());
      })
      .catch((error) => {
        dispatch(errorsNotification(error.response.data.message));
        dispatch(finishLoading());
      });
  };
};

export const getProfile = () => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .get("/api/user")
      .then((response) => {
        dispatch(setProfile(response.data.data));
        dispatch(finishLoading());
      })
      .catch((error) => {
        dispatch(notAuthorized());
        dispatch(finishLoading());
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .get("/sanctum/csrf-cookie")
      .then((res) => {
        axios
          .post("/logout")
          .then((response) => {
            dispatch(discardProfile());
            dispatch(successNotification(response.data.message));
            dispatch(finishLoading());
          })
          .catch((error) => {
            dispatch(errorsNotification(error.response.data.message));
            dispatch(finishLoading());
          });
      })
      .catch((error) => {
        dispatch(finishLoading());
      });
  };
};

export const deleteProfile = (id, callback) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .delete("/api/user/" + id)
      .then((response) => {
        dispatch(discardProfile());
        dispatch(successNotification(response.data.message));
        dispatch(finishLoading());
        callback();
      })
      .catch((error) => {
        dispatch(errorsNotification(error.response.data.message));
        dispatch(finishLoading());
      });
  };
};

export const forgotPassword = (email) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post("api/user/forgot-password", {
        email,
      })
      .then((response) => {
        dispatch(successNotification(response.data.message));
        dispatch(finishLoading());
      })
      .catch((error) => {
        console.log("error", error);
        if (error.response) {
          if (error.response.status === 422) {
            Object.keys(error.response.data.errors).map((errorData) =>
              error.response.data.errors[errorData].map((message) =>
                dispatch(errorsNotification(message))
              )
            );
          } else dispatch(errorsNotification(error.response.data.message));
        }
        dispatch(finishLoading());
      });
  };
};

export const resetPassword = (passwords, token, email, history) => {
  passwords = {
    ...passwords,
    email: email,
    token: token,
  };
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post(`api/user/change-password/${token}`, {
        ...passwords,
      })
      .then((response) => {
        dispatch(successNotification(response.data.message));
        dispatch(finishLoading());
        history.push("/login/auth");
      })
      .catch((error) => {
        console.log("error", error);
        if (error.response) {
          if (error.response.status === 422) {
            Object.keys(error.response.data.errors).map((errorData) =>
              error.response.data.errors[errorData].map((message) =>
                dispatch(errorsNotification(message))
              )
            );
          } else dispatch(errorsNotification(error.response.data.message));
        }
        dispatch(finishLoading());
      });
  };
};
