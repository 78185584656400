import React from "react";
import "./AboutUs.scss";

import PageLayout from "../../../hoc/PageLayout/PageLayout";
import Team from "../../../components/AboutUs/Team/Team";

import Ballons from "../../../assets/AboutUs/Ballons.png";

import TeamData from "../../../data/team.json";
import { locale } from "../../../translate/spain";

const AboutUs = (props) => {
  return (
    <PageLayout b2b={props.location.pathname !== "/about-us"} footer>
      <div className="AboutUs">
        <div className="header">
          <div className="container">
            <div className="text">
              <h2>{locale.aboutUs.title}</h2>
              <p>{locale.aboutUs.description}</p>
            </div>
            <div className="img">
              <img src={Ballons} alt="About us" />
            </div>
          </div>
        </div>
        <div className="spotlight">
          <div className="container">
            <h2>{locale.aboutUs.title2}</h2>
            <p>{locale.aboutUs.description2}</p>
          </div>
        </div>
        <div className="team">
          <div className="container">
            <h2>{locale.aboutUs.teamTitle}</h2>
            <p>{locale.aboutUs.teamDesc}</p>
            <Team team={TeamData} />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default AboutUs;
