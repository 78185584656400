import React from "react";
import "./PlaceNavigation.scss";

// import { HiArrowLeft } from "react-icons/hi";

import { Link } from "react-scroll";
import { locale } from "../../../translate/spain";

const placeNavigation = (props) => {
  return (
    <div className="PlaceNavigation">
      <Link
        activeClass="active"
        spy={true}
        to="overview"
        smooth={true}
        offset={-120}
        duration={500}
      >
        {locale.place.information}
      </Link>
      <Link
        activeClass="active"
        spy={true}
        to="services"
        smooth={true}
        offset={-120}
        duration={500}
      >
        {locale.place.services}
      </Link>
      <Link
        activeClass="active"
        spy={true}
        to="personell"
        smooth={true}
        offset={-120}
        duration={500}
      >
        {locale.place.personell}
      </Link>
      <Link
        activeClass="active"
        spy={true}
        to="reviews"
        smooth={true}
        offset={-120}
        duration={500}
      >
        {locale.place.reviews}
      </Link>
    </div>
  );
};

export default placeNavigation;
