import React, { useState } from "react";
import "./SingleReview.scss";
import { AiFillCheckCircle } from "react-icons/ai";
import { FaRegUser } from "react-icons/fa";
import { FiFlag } from "react-icons/fi";
import { returnCharsName } from "../../../helpers/nameLength";

import Stars from "react-stars-display";

const SingleReview = ({ review, onReportClick }) => {
  const [showAll, setShowAll] = useState(false);
  const [showVerified, setShowVerified] = useState(false);

  return (
    <div className="Review">
      <div className="flex">
        <div className="description">
          <div className="image">
            <FaRegUser />
          </div>
          <div className="name">
            <p>{review.author_name}</p>
            <Stars stars={review.rating} size={18} fill="#FFC830" />
          </div>
          <div className="date">
            {/* <span>{review.date}</span> */}
            <span>hace 5 días</span>
          </div>
          <div className="imgMobile">
            <div className="image">
              <FaRegUser />
            </div>
            <p>{review.author_name}</p>
          </div>
          <div className="dateMobile">
            <Stars stars={review.rating} size={18} fill="#FFC830" />
            {/* <span>{review.date}</span> */}
            <span>hace 5 días</span>
          </div>
        </div>

        <div className="verified-report">
          <div
            className="verified"
            onMouseOver={() => setShowVerified(true)}
            onMouseOut={() => setShowVerified(false)}
          >
            <p>RESEÑA VERIFICADA</p>
            <AiFillCheckCircle />
          </div>

          <div className="report" onClick={onReportClick}>
            <span>Report</span>
            <FiFlag />
          </div>
        </div>
      </div>
      <p className="reviewText">
        {review.text.length > 137
          ? showAll
            ? review.text
            : returnCharsName(review.text, 137)
          : review.text}
      </p>
      {!showAll && review.text.length > 137 && (
        <p className="seeMore" onClick={() => setShowAll(true)}>
          Show more
        </p>
      )}
      <div className="verified-report-mobile">
        <div
          className="verified"
          onMouseOver={() => setShowVerified(true)}
          onMouseOut={() => setShowVerified(false)}
        >
          <p>RESEÑA VERIFICADAk</p>
          <AiFillCheckCircle />
        </div>

        <div className="report" onClick={onReportClick}>
          <span>Report</span>
          <FiFlag />
        </div>
      </div>
      {/* VERIFIED CLOUD */}
      <div className={"verificationCloud" + (showVerified ? " showCloud" : "")}>
        <p>
          Denna omdömeslämnare har automatiskt bjudits in till att skriva ett
          omdöme efter sin upplevelse med företaget. Omdömen som samlas in på
          detta sätt markeras automatiskt som verifierade.
        </p>
        {/* <div className="cloudThick" /> */}
      </div>
    </div>
  );
};

export default SingleReview;
