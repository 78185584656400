import React from "react";
import "./ServicesDisplay.scss";

import { Link } from "react-router-dom";
import { MdKeyboardArrowDown } from "react-icons/md";
import { BsChevronRight } from "react-icons/bs";
import { locale } from "../../../translate/spain";

const servicesDisplay = (props) => {
  const handleClick = (e) => {
    e.target.classList.toggle("expand");
  };

  const mapServices = (category, catIndex) => {
    return (
      category &&
      category.map((object, index) => (
        <div
          key={index}
          className={
            "Service" +
            (props.choose ? " choose" : "") +
            (props.choose
              ? catIndex === props.choosen.category &&
                index === props.choosen.service
                ? " booked"
                : ""
              : "")
          }
          onClick={() =>
            props.chooseService &&
            props.chooseService(
              object.name,
              object.duration,
              object.price,
              object.id
            )
          }
        >
          <div className="description">
            <p>{object.name}</p>
            <span>
              {object.duration} {locale.minutes}{" "}
            </span>
            <span className="price">
              {" "}
              {locale.currency}
              {object.price}
            </span>
          </div>
          <div className="right">
            <div className="price">
              <p>
                {object.price}
                {locale.currency}
              </p>
            </div>
            {props.boka && (
              <Link
                to={`/book/${props.place.id}?service_id=${object.id}&service_name=${object.name}&duration=${object.duration}&price=${object.price}`}
                className="boka"
              >
                {locale.reserve}
              </Link>
            )}
            {props.choose && (
              <button className="choose">
                <BsChevronRight />
              </button>
            )}
          </div>
        </div>
      ))
    );
  };

  const services = props.services.map((category, index) => {
    return (
      <div className="Category" key={index}>
        <div className="title expand" onClick={handleClick}>
          <h5>{category && category.name}</h5>
          <MdKeyboardArrowDown />
        </div>

        {mapServices(category.services, index)}
      </div>
    );
  });

  return <div className="ServicesDisplay">{services}</div>;
};

export default servicesDisplay;
